<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.kelompok.$invalid && submitted }"
            >Kelompok *</label
          >
          <InputText
            v-model="form.kelompok"
            :class="{ 'p-invalid': v$.form.kelompok.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.kelompok.$invalid && submitted) ||
              v$.form.kelompok.$pending.$response
            "
            class="p-error"
            >{{ v$.form.kelompok.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label
            :class="{ 'p-error': v$.form.keterangan.$invalid && submitted }"
            >Keterangan *</label
          >
          <InputText
            v-model="form.keterangan"
            :class="{ 'p-invalid': v$.form.keterangan.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.keterangan.$invalid && submitted) ||
              v$.form.keterangan.$pending.$response
            "
            class="p-error"
            >{{ v$.form.keterangan.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0
        ? 'Tambah Kelompok Supplier'
        : 'Edit Kelompok Supplier'
    },
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        kelompok: {
          required: helpers.withMessage('Kelompok harus diisi.', required),
        },
        keterangan: {
          required: helpers.withMessage('Keterangan harus diisi.', required),
        },
      },
    }
  },
}
</script>
