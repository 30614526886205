<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label
            :class="{ 'p-error': v$.form.kode_kemenkes.$invalid && submitted }"
            >E-Report *</label
          >
          <InputText
            v-model="form.kode_kemenkes"
            type="text"
            class="w-full"
            :class="{
              'p-invalid': v$.form.kode_kemenkes.$invalid && submitted,
            }"
          />
          <small
            v-if="
              (v$.form.kode_kemenkes.$invalid && submitted) ||
              v$.form.kode_kemenkes.$pending.$response
            "
            class="p-error"
            >{{ v$.form.kode_kemenkes.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label
            :class="{ 'p-error': v$.form.kelompok_id.$invalid && submitted }"
            >Kelompok Supplier *</label
          >
          <Dropdown
            v-model="form.kelompok_id"
            :options="kelompokSupplier"
            optionValue="id"
            optionLabel="kelompok"
            :class="{
              'p-invalid': v$.form.kelompok_id.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.kelompok_id.$invalid && submitted) ||
              v$.form.kelompok_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.kelompok_id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
            >Nama Supplier *</label
          >
          <InputText
            v-model="form.nama"
            :class="{
              'p-invalid': v$.form.nama.$invalid && submitted,
            }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.nama.$invalid && submitted) ||
              v$.form.nama.$pending.$response
            "
            class="p-error"
            >{{ v$.form.nama.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label
            :class="{ 'p-error': v$.form.nama_alias.$invalid && submitted }"
            >Nama Supplier (alias) *</label
          >
          <InputText
            v-model="form.nama_alias"
            :class="{
              'p-invalid': v$.form.nama_alias.$invalid && submitted,
            }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.nama_alias.$invalid && submitted) ||
              v$.form.nama_alias.$pending.$response
            "
            class="p-error"
            >{{ v$.form.nama_alias.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label>Kontak</label>
          <InputText v-model="form.kontak" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Status</label>
          <Dropdown
            v-model="form.status"
            :options="[
              { kode: 'aktif', nama: 'Aktif' },
              { kode: 'non-aktif', nama: 'Non Aktif' },
            ]"
            optionValue="kode"
            optionLabel="nama"
            class="w-full"
          />
        </div>
        <div class="field col-12">
          <label>Alamat</label>
          <InputText v-model="form.alamat" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Kota</label>
          <InputText v-model="form.kota" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Kode pos</label>
          <InputText v-model="form.kode_pos" type="number" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Telepon (1)</label>
          <InputText v-model="form.telepon_1" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Telepon (2)</label>
          <InputText v-model="form.telepon_2" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>No. HP salesman (1)</label>
          <InputText v-model="form.hp_1" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>No. HP salesman (2)</label>
          <InputText v-model="form.hp_2" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Fax</label>
          <InputText v-model="form.fax" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>NPWP</label>
          <InputText v-model="form.npwp" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-4">
          <label>Status pajak</label>
          <Dropdown
            v-model="form.status_pajak"
            :options="[
              { kode: 'pkp', nama: 'PKP' },
              { kode: 'non-pkp', nama: 'Non PKP' },
            ]"
            optionValue="kode"
            optionLabel="nama"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Email</label>
          <InputText v-model="form.email" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-4">
          <label>T.O.P</label>
          <InputText v-model="form.top" type="number" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.no_izin.$invalid && submitted }"
            >No Izin supplier *</label
          >
          <InputText
            v-model="form.no_izin"
            type="text"
            class="w-full"
            :class="{
              'p-invalid': v$.form.no_izin.$invalid && submitted,
            }"
          />
          <small
            v-if="
              (v$.form.no_izin.$invalid && submitted) ||
              v$.form.no_izin.$pending.$response
            "
            class="p-error"
            >{{ v$.form.no_izin.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label
            :class="{ 'p-error': v$.form.izin_validity.$invalid && submitted }"
            >Masa berlaku izin supplier *</label
          >
          <Calendar
            v-model="form.izin_validity"
            :monthNavigator="true"
            :yearNavigator="true"
            :yearRange="yearRangeExpiry"
            :class="{
              'p-invalid': v$.form.izin_validity.$invalid && submitted,
            }"
            class="w-full"
            dateFormat="dd/mm/yy"
          />
          <small
            v-if="
              (v$.form.izin_validity.$invalid && submitted) ||
              v$.form.izin_validity.$pending.$response
            "
            class="p-error"
            >{{ v$.form.izin_validity.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.no_cdob.$invalid && submitted }"
            >No Izin CDOB supplier *</label
          >
          <InputText
            v-model="form.no_cdob"
            type="text"
            :class="{
              'p-invalid': v$.form.no_cdob.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.no_cdob.$invalid && submitted) ||
              v$.form.no_cdob.$pending.$response
            "
            class="p-error"
            >{{ v$.form.no_cdob.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label
            :class="{ 'p-error': v$.form.cdob_validity.$invalid && submitted }"
            >Masa berlaku izin CDOB supplier *</label
          >
          <Calendar
            v-model="form.cdob_validity"
            :monthNavigator="true"
            :yearNavigator="true"
            :yearRange="yearRangeExpiry"
            :class="{
              'p-invalid': v$.form.cdob_validity.$invalid && submitted,
            }"
            class="w-full"
            dateFormat="dd/mm/yy"
          />
          <small
            v-if="
              (v$.form.cdob_validity.$invalid && submitted) ||
              v$.form.cdob_validity.$pending.$response
            "
            class="p-error"
            >{{ v$.form.cdob_validity.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Spesimen</label>
          <InputText
            type="file"
            class="w-full"
            accept="application/pdf"
            @change="setSpesimen"
            maxlength="5000000"
          />
          <template v-if="spesimen">
            <div style="width: 100%; text-align: center; padding: 10px 2px">
              <embed
                :src="spesimen"
                style="width: calc(100% * 2 / 3); height: 300px; margin: auto"
              />
            </div>
          </template>
        </div>

        <Divider align="left">
          <div class="inline-flex align-items-center">
            <i class="pi pi-user mr-2"></i>
            <b>Apoteker</b>
          </div>
        </Divider>
        <div class="field col-12 md:col-6">
          <label
            >Nama</label
          >
          <InputText
            v-model="form.apoteker.nama"
            type="text"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>Alamat</label>
          <InputText
            v-model="form.apoteker.alamat"
            type="text"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>Tempat lahir</label>
          <InputText
            v-model="form.apoteker.tempat_lahir"
            type="text"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>Tanggal lahir</label>
          <Calendar
            v-model="form.apoteker.tanggal_lahir"
            dateFormat="dd/mm/yy"
            :monthNavigator="true"
            :yearNavigator="true"
            yearRange="1970:2004"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Telepon</label>
          <InputText
            v-model="form.apoteker.telepon"
            type="text"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>HP (1)</label>
          <InputText v-model="form.apoteker.hp_1" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-4">
          <label>HP (2)</label>
          <InputText v-model="form.apoteker.hp_2" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>No SIPA</label>
          <InputText
            v-model="form.apoteker.no_sipa"
            type="text"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>Masa berlaku SIPA</label>
          <Calendar
            v-model="form.apoteker.sipa_validity"
            :monthNavigator="true"
            :yearNavigator="true"
            :yearRange="yearRangeExpiry"
            dateFormat="dd/mm/yy"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>No STRA</label>
          <InputText
            v-model="form.apoteker.no_stra"
            type="text"
            class="w-full"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>Masa berlaku STRA</label>
          <Calendar
            v-model="form.apoteker.stra_validity"
            :monthNavigator="true"
            :yearNavigator="true"
            :yearRange="yearRangeExpiry"
            dateFormat="dd/mm/yy"
            class="w-full"
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    kelompokSupplier: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      yearRangeExpiry: dayjs().year() + ':' + (parseInt(dayjs().year()) + 15),
      spesimen: this.item.spesimen,
      fileSpesimen: null,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0 ? 'Tambah user' : 'Edit user'
    },
    form() {
      let form = this.item

      if (form && !form.apoteker) {
        form.apoteker = {
          nama: '',
          alamat: '',
          tempat_lahir: '',
          tanggal_lahir: '',
          telepon: '',
          hp_1: '',
          hp_2: '',
          no_sipa: '',
          sipa_validity: '',
          no_stra: '',
          stra_validity: '',
        }
      }

      return form
        ? form
        : {
            kelompok_id: 0,
            kode: '',
            kode_kemenkes: '',
            nama: '',
            nama_alias: '',
            kontak: '',
            alamat: '',
            kota: '',
            kode_pos: '',
            telepon_1: '',
            telepon_2: '',
            hp_1: '',
            hp_2: '',
            fax: '',
            email: '',
            npwp: '',
            status_pajak: '',
            no_izin: '',
            izin_validity: '',
            no_cdob: '',
            cdob_validity: '',
            top: 0,
            apoteker: {
              nama: '',
              alamat: '',
              tempat_lahir: '',
              tanggal_lahir: '',
              telepon: '',
              hp_1: '',
              hp_2: '',
              no_sipa: '',
              sipa_validity: '',
              no_stra: '',
              stra_validity: '',
            },
          }
    },
  },
  methods: {
    handleSubmit(isFormValid) {
    this.submitted = true;

      if (!isFormValid) {
        return
      }

      // fix date, primevue calendar output date format = 2021-07-13T17:00:00.000Z -> 2021-07-14
      this.form.izin_validity = this.form.izin_validity
        ? dayjs(this.form.izin_validity).format('YYYY-MM-DD')
        : null

      this.form.cdob_validity = this.form.cdob_validity
        ? dayjs(this.form.cdob_validity).format('YYYY-MM-DD')
        : null

      this.form.apoteker.sipa_validity = this.form.apoteker.sipa_validity
        ? dayjs(this.form.apoteker.sipa_validity).format('YYYY-MM-DD')
        : null

      this.form.apoteker.stra_validity = this.form.apoteker.stra_validity
        ? dayjs(this.form.apoteker.stra_validity).format('YYYY-MM-DD')
        : null

      this.form.apoteker.tanggal_lahir = this.form.apoteker.tanggal_lahir
        ? dayjs(this.form.apoteker.tanggal_lahir).format('YYYY-MM-DD')
        : null

      if (this.fileSpesimen && this.fileSpesimen.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Spesimen',
          detail: 'Ukuran file Spesimen melebihi 5 MB',
          life: 3000,
        })
        return
      }

      this.submitted = true

      this.$emit('save', this.form, this.fileSpesimen)
    },
    setSpesimen(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.spesimen = e.target.result
        }
        this.fileSpesimen = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
  },
  validations() {
    return {
      form: {
        kode_kemenkes: {
          required: helpers.withMessage('Kode E-Report harus diisi.', required),
        },
        kelompok_id: {
          required: helpers.withMessage(
            'Kelompok supplier harus diisi.',
            required
          ),
        },
        nama: {
          required: helpers.withMessage('Nama supplier harus diisi.', required),
        },
        nama_alias: {
          required: helpers.withMessage(
            'Nama alias supplier harus diisi.',
            required
          ),
        },
        no_izin: {
          required: helpers.withMessage(
            'No izin supplier harus diisi.',
            required
          ),
        },
        izin_validity: {
          required: helpers.withMessage(
            'Masa berlaku izin supplier harus diisi.',
            required
          ),
        },
        no_cdob: {
          required: helpers.withMessage(
            'No Izin CDOB supplier harus diisi.',
            required
          ),
        },
        cdob_validity: {
          required: helpers.withMessage(
            'Masa berlaku izin CDOB supplier harus diisi.',
            required
          ),
        },
      },
    }
  },
}
</script>
