<template>
  <div class="text-center">
    <img class="img-fluid" style="max-width: 100%; max-height: 100%" :src="form.src" />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    form() {
      console.log('item', this.item)
      return this.item
    },
  },
}
</script>
