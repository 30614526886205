<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="kode" header="Kode" style="width: 250px" sortable>
        <template #body="{ data }">
          {{ data.kode }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            placeholder="Kode"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column field="nama" header="Golongan" sortable>
        <template #body="{ data }">
          {{ data.nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cari golongan obat"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <DataTable
          v-if="slotProps.data.golongan_sub.length > 0"
          :value="slotProps.data.golongan_sub"
          :scrollable="true"
          class="p-datatable-sm"
          showGridlines
        >
          <Column field="kode" header="Kode" style="width: 100px" sortable>
            <template #body="{ data }">
              {{ data.kode }}
            </template>
          </Column>
          <Column
            field="nama"
            header="Golongan"
            style="min-width: 250px"
            sortable
          >
            <template #body="{ data }">
              {{ data.nama }}
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="$emit('edit', data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="$emit('delete', data)"
              />
            </template>
          </Column>
        </DataTable>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      expandedRows: [],
      options: this.gridOptions,
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
  },
}
</script>
