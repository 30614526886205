<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Komisi</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Komisi</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-10 lg:col-offset-1">
        <div class="card bg-white">
          <div class="flex justify-content-end mb-2">
            <Button
              icon="pi pi-plus"
              label="Komisi"
              class="mr-2"
              @click="onAddData()"
            />
            <Button
              :loading="isLoadingExport"
              icon="pi pi-file-excel"
              label="Export"
              class="p-button-outlined"
              @click="onExport()"
            />
          </div>
          <grid-komisi
            :items="items"
            :loading="isLoading"
            @edit="onEditData"
            @delete="onConfirmDeleteData"
          />
        </div>
      </div>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit Komisi' : 'Tambah Komisi'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '25vw' }"
      :modal="true"
    >
      <form-komisi :item="form" :loading="isLoadingSave" @save="onSaveData" />
    </Dialog>
    <Dialog
      header="Hapus Komisi"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Komisi <strong>{{ form.termin }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteData"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import KomisiService from '@/services/KomisiService'
import errorHandler from '@/helpers/error-handler'
import GridKomisi from '@/components/master/GridKomisi'
import FormKomisi from '@/components/master/FormKomisi'

export default {
  name: 'Komisi',
  components: {
    FormKomisi,
    GridKomisi,
  },
  data() {
    return {
      komisiService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
    }
  },
  created() {
    this.komisiService = new KomisiService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.komisiService
        .get()
        .then((res) => {
          const data = res.data.data
          data.forEach((item) => {
            const termin_from = item.termin_from.toString()
            const termin_to = item.termin_to.toString()
            let termin = termin_from.concat(' - ', termin_to)
            if (item.termin_to === 0) termin = '>= '.concat(termin_from)
            item['termin'] = termin
          })
          this.items = data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data komisi', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onAddData() {
      this.form = {
        id: 0,
        termin: '',
        termin_from: 0,
        termin_to: 0,
        persentase: 0.0,
      }
      this.dialog = true
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      if (data) {
        const newData = Object.assign({}, data)
        delete newData.termin
        data = newData
      }

      if (data && data.id === 0) {
        this.komisiService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Komisi',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Komisi', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        this.komisiService
          .update(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Komisi',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Komisi', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeleteData(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteData() {
      this.komisiService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Komisi',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Komisi', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onExport() {
      this.isLoadingExport = true
      this.komisiService
        .export()
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-komisi.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Komisi', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
  },
}
</script>
