<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-4">
        <label>Role</label>
        <InputText v-model="form.name" type="text" class="w-full" disabled />
      </div>
      <div class="field col-4">
        <label>Display Name</label>
        <InputText
          v-model="form.display_name"
          type="text"
          class="w-full"
          disabled
        />
      </div>
      <div class="field col-12">
        <label>Description</label>
        <InputText
          v-model="form.description"
          type="text"
          class="w-full"
          disabled
        />
      </div>
      <div class="field col-12">
        <grid-permission
          :loading="isLoadingSave"
          :items="permissions"
          :selectedPermission="selected"
          @save="onSavePermission"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GridPermission from '@/components/master/GridPermission'
import RoleService from '@/services/RoleService'
import errorHandler from '@/helpers/error-handler'

export default {
  components: {
    GridPermission,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          id: 0,
          name: '',
          display_name: '',
          description: '',
        }
      },
    },
    permissions: {
      type: Array,
      default() {
        return []
      },
    },
    selected: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    form() {
      return this.item
    },
  },
  data() {
    return {
      isLoadingSave: false,
    }
  },
  methods: {
    onSavePermission(e) {
      const roleService = new RoleService()
      const form = {
        id: this.form.id,
        permissions: e,
      }
      this.isLoadingSave = true
      if (e.length > 0) {
        roleService
          .sync(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Role',
                detail: 'Data berhasil disimpan',
                life: 3000,
              })
              this.isLoadingSave = false
              this.$emit('close')
              this.$router.push({ name: 'Role' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Role', this)
          })
      } else {
        roleService
          .clear(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Role',
                detail: 'Data berhasil disimpan',
                life: 3000,
              })
              this.isLoadingSave = false
              this.$emit('close')
              this.$router.push({ name: 'Role' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Role', this)
          })
      }
    },
  },
}
</script>
