<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.kode.$invalid && submitted }"
            >Kode Pabrik *</label
          >
          <InputText
            v-model="form.kode"
            :class="{ 'p-invalid': v$.form.kode.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.kode.$invalid && submitted) ||
              v$.form.kode.$pending.$response
            "
            class="p-error"
            >{{ v$.form.kode.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label>Kode E-Report (KEMENKES)</label>
          <InputText v-model="form.kode_kemenkes" type="text" class="w-full" />
        </div>
        <div class="field col-12">
          <label :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
            >Nama Pabrik *</label
          >
          <InputText
            v-model="form.nama"
            :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.nama.$invalid && submitted) ||
              v$.form.nama.$pending.$response
            "
            class="p-error"
            >{{ v$.form.nama.required.$message }}</small
          >
        </div>
        <div class="field col-12">
          <label>Alamat</label>
          <InputText v-model="form.alamat" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-6">
          <label>Status</label>
          <Dropdown
            v-model="form.status"
            :options="[
              { kode: 'aktif', nama: 'Aktif' },
              { kode: 'non-aktif', nama: 'Non Aktif' },
            ]"
            optionValue="kode"
            optionLabel="nama"
            class="w-full"
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0 ? 'Tambah Pabrik' : 'Edit Pabrik'
    },
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        kode: {
          required: helpers.withMessage('Kode Pabrik harus diisi.', required),
        },
        nama: {
          required: helpers.withMessage('Nama Pabrik harus diisi.', required),
        },
      },
    }
  },
}
</script>
