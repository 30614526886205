import http from '../clients/Axios'

export default class KomposisiObatService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/obat/komposisi/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/obat/komposisi${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/obat/komposisi', form)
    return res
  }

  async update(form) {
    const res = await http.put(`/obat/komposisi/${form.id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/obat/komposisi/${id}`)
    return res
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/obat/komposisi/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
