import http from '../clients/Axios'

export default class MarginGobalService {
  async paginate(
    pabrik_id = 0,
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/pabrik/${pabrik_id}/margin/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async add(form) {
    const res = await http.post(`/pabrik/${form.pabrik_id}/margin`, form)
    return res
  }

  async update(form) {
    const res = await http.put(
      `/pabrik/${form.pabrik_id}/margin/${form.id}`,
      form
    )
    return res
  }

  async delete(form) {
    const res = await http.delete(`/pabrik/${form.pabrik_id}/margin/${form.id}`)
    return res
  }
}
