<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="flex justify-content-end mb-2">
        <Button
          :loading="isLoadingSave"
          icon="pi pi-save"
          class="mr-2"
          label="Simpan"
          type="submit"
        />
        <Button
          class="p-button-link"
          label="Kembali"
          @click="$router.push({ name: 'Obat' })"
        />
      </div>
      <div class="grid">
        <div class="col-12 md:col-7">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <label
                :class="{ 'p-error': v$.form.pabrik_id.$invalid && submitted }"
                >Pabrik **</label
              >
              <Dropdown
                v-model="form.pabrik_id"
                :filter="true"
                :options="list_pabrik"
                :class="{
                  'p-invalid': v$.form.pabrik_id.$invalid && submitted,
                }"
                optionValue="id"
                optionLabel="nama"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.pabrik_id.$invalid && submitted) ||
                  v$.form.pabrik_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.pabrik_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label
                :class="{
                  'p-error': v$.form.supplier_id.$invalid && submitted,
                }"
                >Supplier *</label
              >
              <Dropdown
                v-model="form.supplier_id"
                :filter="true"
                :options="list_supplier"
                :class="{
                  'p-invalid': v$.form.supplier_id.$invalid && submitted,
                }"
                optionValue="id"
                optionLabel="nama"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.supplier_id.$invalid && submitted) ||
                  v$.form.supplier_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.supplier_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label
                :class="{
                  'p-error': v$.form.golongan_id.$invalid && submitted,
                }"
                >Golongan *</label
              >
              <Dropdown
                v-model="form.golongan_id"
                :filter="true"
                :options="list_golongan_obat"
                :class="{
                  'p-invalid': v$.form.golongan_id.$invalid && submitted,
                }"
                optionValue="id"
                optionLabel="nama"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.golongan_id.$invalid && submitted) ||
                  v$.form.golongan_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.golongan_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Komposisi</label>
              <AutoComplete
                v-model="form.komposisi"
                :min-length="1"
                :suggestions="filteredKomposisi"
                class="w-full"
                input-class="w-full"
                placeholder="Komposisi obat"
                field="komposisi"
                @complete="searchKomposisi($event)"
              >
                <template #item="slotProps">
                  <div>
                    {{ slotProps.item.kode }} -
                    {{ slotProps.item.komposisi }}
                  </div>
                </template>
              </AutoComplete>
            </div>
            <div class="field col-12 md:col-6">
              <label>Satuan </label>
              <span class="p-fluid">
                <AutoComplete
                  v-model="form.satuan"
                  :min-length="1"
                  :suggestions="list_satuan"
                  :multiple="true"
                  field="nama"
                  @complete="searchSatuan($event)"
                />
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <label
                :class="{
                  'p-error': v$.form.satuan_tkcl.$invalid && submitted,
                }"
                >Satuan Terkecil *</label
              >
              <AutoComplete
                v-model="form.satuan_tkcl"
                :min-length="1"
                :suggestions="list_satuan_terkecil"
                :class="{
                  'p-invalid': v$.form.satuan_tkcl.$invalid && submitted,
                }"
                class="w-full"
                input-class="w-full"
                field="nama"
                @complete="searchSatuan($event)"
              >
                <template #item="slotProps">
                  <div>
                    {{ slotProps.item.kode }} - {{ slotProps.item.nama }}
                  </div>
                </template>
              </AutoComplete>
              <small
                v-if="
                  (v$.form.satuan_tkcl.$invalid && submitted) ||
                  v$.form.satuan_tkcl.$pending.$response
                "
                class="p-error"
                >{{ v$.form.satuan_tkcl.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Jenis obat</label>
              <Dropdown
                v-model="form.jenis"
                optionValue="kode"
                optionLabel="nama"
                :options="[
                  { kode: 'paten', nama: 'Paten' },
                  { kode: 'generik', nama: 'Generik' },
                ]"
                class="w-full"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>Bentuk & ketersediaan</label>
              <AutoComplete
                v-model="form.bentuk_sediaan"
                :min-length="2"
                :suggestions="ac_list_bentuk_sediaan"
                field="nama"
                class="w-full"
                input-class="w-full"
                placeholder="Bentuk & ketersediaan"
                autofocus
                @complete="searchBentukSediaan($event)"
                @item-select="selectBentukSediaan"
              >
              </AutoComplete>
            </div>
            <div class="field col-12 md:col-6">
              <label>Zat aktif</label>
              <AutoComplete
                v-model="form.zat_aktif"
                :min-length="2"
                :suggestions="ac_list_zat_aktif"
                field="nama"
                class="w-full"
                input-class="w-full"
                placeholder="Zat aktif"
                autofocus
                @complete="searchZatAktif($event)"
                @item-select="selectZatAktif"
              >
              </AutoComplete>
            </div>
            <div class="field col-12 md:col-6">
              <label>Kode</label>
              <InputText v-model="form.kode" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-4">
              <label>Kode SIODIE</label>
              <InputText
                v-model="form.kode_siodie"
                type="text"
                class="w-full"
              />
            </div>
            <div
              class="field col-12 md:col-2"
              style="display: flex; align-items: end"
            >
            <div style="display: flex; align-items: center; height: 35px;">
                <Checkbox
                  id="inactive_siodie"
                  v-model="form.inactive_siodie"
                  :binary="true"
                  class="mr-1"
                />
                <label for="inactive_siodie">NON AKTIF</label>
              </div>
            </div>
            <div class="field col-12 md:col-4">
              <label>Kode E-REPORT</label>
              <InputText
                v-model="form.kode_ereport"
                type="text"
                class="w-full"
              />
            </div>
            <div
              class="field col-12 md:col-2"
              style="display: flex; align-items: end"
            >
            <div style="display: flex; align-items: center; height: 35px;">
                <br />
                <Checkbox
                  id="inactive_ereport"
                  v-model="form.inactive_ereport"
                  :binary="true"
                  class="mr-1"
                />
                <label for="inactive_ereport">NON AKTIF</label>
              </div>
            </div>
            <div class="field col-12 md:col-4">
              <label>Kode NIE</label>
              <InputText v-model="form.kode_nie" type="text" class="w-full" />
            </div>
            <div
              class="field col-12 md:col-2"
              style="display: flex; align-items: end"
            >
            <div style="display: flex; align-items: center; height: 35px;">
                <Checkbox
                  id="inactive_nie"
                  v-model="form.inactive_nie"
                  :binary="true"
                  class="mr-1"
                />
                <label for="inactive_nie">NON AKTIF</label>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label>Kategori</label>
              <Dropdown
                v-model="form.kategori_id"
                :filter="true"
                :options="list_kategori_obat"
                optionValue="id"
                optionLabel="nama"
                class="w-full"
              />
            </div>
            <div class="field col-12">
              <label :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
                >Nama *</label
              >
              <InputText
                v-model="form.nama"
                :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
                type="text"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama.$invalid && submitted) ||
                  v$.form.nama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama.required.$message }}</small
              >
            </div>
            <div class="field col-12">
              <label>Nama (alias)</label>
              <InputText v-model="form.nama_alias" type="text" class="w-full" />
            </div>
            <div class="field col-12">
              <label>Nama NIE</label>
              <InputText v-model="form.nama_nie" type="text" class="w-full" />
            </div>
            <div class="field col-12">
              <label>SATUAN NIE</label>
              <InputText v-model="form.satuan_nie" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-3">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                optionValue="key"
                optionLabel="label"
                :options="[
                  { key: 'aktif', label: 'Aktif' },
                  { key: 'non-aktif', label: 'Non Aktif' },
                ]"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Stock minimum</label>
              <InputNumber
                v-model="form.stok_minimum"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga sebelum PPN</label>
              <InputNumber
                v-model="form.margin.harga"
                :minFractionDigits="0"
                :maxFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                @input="updatePPN"
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Modal + PPN</label>
              <InputNumber
                v-model="form.margin.harga_ppn"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>

            <div class="field col-12 md:col-2">
              <label>Margin Kotor A</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_bruto_A"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  @input="inputMarginA"
                />
                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Bersih A</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_netto_A"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Rp A</label>
              <InputNumber
                v-model="form.margin.margin_A"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual A (PPN)</label>
              <InputNumber
                v-model="form.margin.harga_jual_ppn_A"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual A</label>
              <InputNumber
                v-model="form.margin.harga_jual_A"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>

            <div class="field col-12 md:col-2">
              <label>Margin Kotor B</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_bruto_B"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  @input="inputMarginB"
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Bersih B</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_netto_B"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Rp B</label>
              <InputNumber
                v-model="form.margin.margin_B"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual B (PPN)</label>
              <InputNumber
                v-model="form.margin.harga_jual_ppn_B"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual B</label>
              <InputNumber
                v-model="form.margin.harga_jual_B"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>

            <div class="field col-12 md:col-2">
              <label>Margin Kotor C</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_bruto_C"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  @input="inputMarginC"
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Bersih C</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_netto_C"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Rp C</label>
              <InputNumber
                v-model="form.margin.margin_C"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual C (PPN)</label>
              <InputNumber
                v-model="form.margin.harga_jual_ppn_C"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual C</label>
              <InputNumber
                v-model="form.margin.harga_jual_C"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>

            <div class="field col-12 md:col-2">
              <label>Margin Kotor D</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_bruto_D"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  @input="inputMarginD"
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Bersih D</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_netto_D"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Rp D</label>
              <InputNumber
                v-model="form.margin.margin_D"
                :minFractionDigits="2"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual D (PPN)</label>
              <InputNumber
                v-model="form.margin.harga_jual_ppn_D"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual D</label>
              <InputNumber
                v-model="form.margin.harga_jual_D"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>

            <div class="field col-12 md:col-2">
              <label>Margin Kotor E</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_bruto_E"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  @input="inputMarginE"
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Bersih E</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_netto_E"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Rp E</label>
              <InputNumber
                v-model="form.margin.margin_E"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual E (PPN)</label>
              <InputNumber
                v-model="form.margin.harga_jual_ppn_E"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual E</label>
              <InputNumber
                v-model="form.margin.harga_jual_E"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>

            <div class="field col-12 md:col-2">
              <label>Margin Kotor F</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_bruto_F"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  @input="inputMarginF"
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Bersih F</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-model="form.margin.margin_netto_F"
                  :minFractionDigits="2"
                  locale="id-ID"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />

                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-2">
              <label>Margin Rp F</label>
              <InputNumber
                v-model="form.margin.margin_F"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual F (PPN)</label>
              <InputNumber
                v-model="form.margin.harga_jual_ppn_F"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Harga Jual F</label>
              <InputNumber
                v-model="form.margin.harga_jual_F"
                :minFractionDigits="0"
                mode="currency"
                currency="IDR"
                locale="id-ID"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="hidden md:block"><Divider layout="vertical" /></div>
        <div class="col">
          <div class="field">
            <label>Foto produk #1</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewProduk1"
              maxlength="5000000"
            />
            <template v-if="fotoProduk1">
              <div style="width: 100%; text-align: center; padding: 10px 2px">
                <img
                  :src="fotoProduk1"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px"
                />
              </div>
            </template>
          </div>
          <div class="field">
            <label>Foto produk #2</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewProduk2"
              maxlength="5000000"
            />
            <template v-if="fotoProduk2">
              <div style="width: 100%; text-align: center; padding: 10px 2px">
                <img
                  :src="fotoProduk2"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px; margin: auto"
                />
              </div>
            </template>
          </div>
          <div class="field">
            <label>Foto produk #3</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewProduk3"
              maxlength="5000000"
            />
            <template v-if="fotoProduk3">
              <div style="width: 100%; text-align: center; padding: 10px 2px">
                <img
                  :src="fotoProduk3"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, minValue } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import GolonganObatService from '@/services/GolonganObatService'
import KomposisiObatService from '@/services/KomposisiObatService'
import KonversiSatuanService from '@/services/KonversiSatuanService'
import PabrikService from '@/services/PabrikService'
import SettingService from '@/services/SettingService'
import SupplierService from '@/services/SupplierService'
import ObatService from '@/services/ObatService'
import ReferenceService from '@/services/ReferenceService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ppn: 0,
      isLoadingSave: false,
      submitted: false,
      fotoProduk1: null,
      fotoProduk2: null,
      fotoProduk3: null,
      fileFotoProduk1: null,
      fileFotoProduk2: null,
      fileFotoProduk3: null,
      konversiSatuanService: null,
      komposisiObatService: null,
      referenceService: null,
      obatService: null,
      list_pabrik: [],
      list_supplier: [],
      list_kategori_obat: [],
      list_golongan_obat: [],
      filteredKomposisi: [],
      filteredSatuan: [],
      list_bentuk_sediaan: [],
      list_zat_aktif: [],
      ac_list_zat_aktif: [],
      ac_list_bentuk_sediaan: [],
      form: {
        pabrik_id: 0,
        supplier_id: 0,
        kategori_id: 0,
        golongan_id: 0,
        komposisi: null,
        satuan: null,
        satuan_tkcl: null,
        jenis: 'paten',
        kode: '',
        kode_siodie: '',
        inactive_siodie: false,
        kode_ereport: '',
        inactive_ereport: false,
        kode_nie: '',
        inactive_nie: false,
        nama: '',
        nama_alias: '',
        status: 'aktif',
        stok_minimum: 0,
        images: [],
        margin: {
          harga: 0,
          harga_ppn: 0,
          margin_netto_A: 0,
          margin_bruto_A: 0,
          margin_A: 0,
          harga_jual_A: 0,
          harga_jual_ppn_A: 0,
          margin_netto_B: 0,
          margin_bruto_B: 0,
          margin_B: 0,
          harga_jual_B: 0,
          harga_jual_ppn_B: 0,
          margin_netto_C: 0,
          margin_bruto_C: 0,
          margin_C: 0,
          harga_jual_C: 0,
          harga_jual_ppn_C: 0,
          margin_netto_D: 0,
          margin_bruto_D: 0,
          margin_D: 0,
          harga_jual_D: 0,
          harga_jual_ppn_D: 0,
          margin_netto_E: 0,
          margin_bruto_E: 0,
          margin_E: 0,
          harga_jual_E: 0,
          harga_jual_ppn_E: 0,
          margin_netto_F: 0,
          margin_bruto_F: 0,
          margin_F: 0,
          harga_jual_F: 0,
          harga_jual_ppn_F: 0,
        },
        bentuk_sediaan: '',
        zat_aktif: '',
      },
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0 ? 'Tambah obat' : 'Edit obat'
    },
    list_satuan() {
      return this.filteredSatuan.filter((el) => el.quantity > 1)
    },
    list_satuan_terkecil() {
      return this.filteredSatuan.filter((el) => el.quantity === 1)
    },
  },
  created() {
    this.konversiSatuanService = new KonversiSatuanService()
    this.komposisiObatService = new KomposisiObatService()
    this.obatService = new ObatService()
    this.referenceService = new ReferenceService()
    const settingService = new SettingService()
    settingService.get('?filter[key]=PPN').then((res) => {
      this.ppn = res.data.data.value
    })
  },
  mounted() {
    const self = this
    Promise.all([
      this.getPabrik(),
      this.getSupplier(),
      this.getGolonganObat(),
      this.getKategori(),
    ]).then(function (result) {
      self.list_pabrik = result[0]
      self.list_supplier = result[1]
      self.list_golongan_obat = result[2]
      self.list_kategori_obat = result[3]
    })
    this.getZatAktif()
    this.getBentukSediaan()
    if (this.id > 0) {
      this.isLoading = true
      this.obatService
        .getById(this.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.margin.harga_ppn = Math.floor(this.form.margin.harga_ppn)

            this.form.inactive_siodie = res.data.data.inactive_siodie === 1 ? true : false
            this.form.inactive_ereport = res.data.data.inactive_ereport === 1 ? true : false
            this.form.inactive_nie = res.data.data.inactive_nie === 1 ? true : false

            if (this.form.pabrik) {
              this.form.pabrik_id = this.form.pabrik.id
            }
            if (this.form.supplier) {
              this.form.supplier_id = this.form.supplier.id
            }
            if (this.form.kategori) {
              this.form.kategori_id = this.form.kategori.id
            }
            if (this.form.golongan) {
              this.form.golongan_id = this.form.golongan.id
            }
            this.hitungMargin('A')
            this.hitungMargin('B')
            this.hitungMargin('C')
            this.hitungMargin('D')
            this.hitungMargin('E')
            this.hitungMargin('F')
            if (this.form.images != null) {
              if (this.form.images.length >= 1) {
                this.fotoProduk1 = this.form.images[0]
              }
              if (this.form.images.length >= 2) {
                this.fotoProduk2 = this.form.images[1]
              }
              if (this.form.images.length >= 3) {
                this.fotoProduk3 = this.form.images[2]
              }
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Edit Obat', this)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  methods: {
    async searchKomposisi(e) {
      await this.komposisiObatService
        .get('?filter[komposisi]=' + e.query)
        .then((res) => {
          this.filteredKomposisi = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data komposisi', this)
        })
    },
    async searchSatuan(e) {
      await this.konversiSatuanService
        .get('?filter[nama]=' + e.query)
        .then((res) => {
          this.filteredSatuan = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Satuan', this)
        })
    },
    async getPabrik() {
      const pabrikService = new PabrikService()
      return await pabrikService
        .get()
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data pabrik', this)
        })
    },
    async getSupplier() {
      const supplierService = new SupplierService()
      return await supplierService
        .get()
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data supplier', this)
        })
    },
    async getKategori() {
      const referenceService = new ReferenceService()
      return await referenceService
        .getKategoriObat()
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data kategori', this)
        })
    },
    async getGolonganObat() {
      const golonganObatService = new GolonganObatService()
      return await golonganObatService
        .get()
        .then((res) => {
          const result = []
          res.data.data.forEach((el) => {
            if (el.golongan_sub && el.golongan_sub.length === 0) {
              result.push({
                id: el.id,
                nama: el.nama,
              })
            } else {
              el.golongan_sub.forEach((sub) => {
                result.push({
                  id: sub.id,
                  nama: sub.nama,
                })
              })
            }
          })

          return result
        })
        .catch((err) => {
          errorHandler(err, 'Data area', this)
        })
    },
    async getZatAktif() {
      await this.referenceService
        .get('zat_aktif')
        .then((res) => {
          const list_zat_aktif = res.data.data
          list_zat_aktif.forEach((el) => {
            this.list_zat_aktif.push({ nama: el })
          })
        })
        .catch((err) => {
          errorHandler(err, 'Data Zat Aktif', this)
        })
    },
    async searchZatAktif(input) {
      if (this.list_zat_aktif) {
        this.ac_list_zat_aktif = []
        this.list_zat_aktif.forEach((el) => {
          const name = el.nama.trim().toLowerCase()
          const query = input.query.toLowerCase()
          if (name.includes(query)) {
            this.ac_list_zat_aktif.push(el)
          }
        })
      }
    },
    async getBentukSediaan() {
      await this.referenceService
        .get('bentuk_sediaan')
        .then((res) => {
          const list_bentuk_sediaan = res.data.data
          list_bentuk_sediaan.forEach((el) => {
            this.list_bentuk_sediaan.push({ nama: el })
          })
        })
        .catch((err) => {
          errorHandler(err, 'Data Bentuk Sediaan', this)
        })
    },
    async searchBentukSediaan(input) {
      if (this.list_bentuk_sediaan) {
        this.ac_list_bentuk_sediaan = []
        this.list_bentuk_sediaan.forEach((el) => {
          const name = el.nama.trim().toLowerCase()
          const query = input.query.toLowerCase()
          if (name.includes(query)) {
            this.ac_list_bentuk_sediaan.push(el)
          }
        })
      }
    },
    selectZatAktif(e) {
      this.form.zat_aktif = e.value.nama
    },
    selectBentukSediaan(e) {
      this.form.bentuk_sediaan = e.value.nama
    },
    updatePPN(e) {
      let harga_diskon = parseFloat(e.value)
      this.form.margin.harga = e.value
      this.form.margin.harga_ppn =
        Math.round(harga_diskon + Math.floor(harga_diskon * (this.ppn / 100)))
      this.hitungMargin('A')
      this.hitungMargin('B')
      this.hitungMargin('C')
      this.hitungMargin('D')
      this.hitungMargin('E')
      this.hitungMargin('F')
    },
    hitungMargin(type) {
      let margin_kotor = 0
      const harga_non_ppn = this.form.margin.harga
      const harga_ppn = this.form.margin.harga_ppn
      switch (type.toUpperCase()) {
        case 'A':
          margin_kotor = this.form.margin.margin_bruto_A
          break
        case 'B':
          margin_kotor = this.form.margin.margin_bruto_B
          break
        case 'C':
          margin_kotor = this.form.margin.margin_bruto_C
          break
        case 'D':
          margin_kotor = this.form.margin.margin_bruto_D
          break
        case 'E':
          margin_kotor = this.form.margin.margin_bruto_E
          break
        case 'F':
          margin_kotor = this.form.margin.margin_bruto_F
          break
      }
      const margin_bersih = Math.round(margin_kotor * 0.9 * 100) / 100

      const margin = Math.round(harga_ppn * (margin_kotor / 100) * 100) / 100
      // const harga_jual_ppn = Math.ceil((harga_ppn + margin) / 10) * 10

      const margin_jual =
        Math.round(harga_non_ppn * (margin_kotor / 100) * 100) / 100
      const harga_jual = Math.ceil((harga_non_ppn + margin_jual) / 10) * 10

      const harga_jual_ppn = Math.floor(harga_jual * (1+(this.ppn / 100)))
      switch (type.toUpperCase()) {
        case 'A':
          this.form.margin.margin_netto_A = margin_bersih
          this.form.margin.margin_A = margin
          this.form.margin.harga_jual_A = harga_jual
          this.form.margin.harga_jual_ppn_A = harga_jual_ppn
          break
        case 'B':
          this.form.margin.margin_netto_B = margin_bersih
          this.form.margin.margin_B = margin
          this.form.margin.harga_jual_B = harga_jual
          this.form.margin.harga_jual_ppn_B = harga_jual_ppn
          break
        case 'C':
          this.form.margin.margin_netto_C = margin_bersih
          this.form.margin.margin_C = margin
          this.form.margin.harga_jual_C = harga_jual
          this.form.margin.harga_jual_ppn_C = harga_jual_ppn
          break
        case 'D':
          this.form.margin.margin_netto_D = margin_bersih
          this.form.margin.margin_D = margin
          this.form.margin.harga_jual_D = harga_jual
          this.form.margin.harga_jual_ppn_D = harga_jual_ppn
          break
        case 'E':
          this.form.margin.margin_netto_E = margin_bersih
          this.form.margin.margin_E = margin
          this.form.margin.harga_jual_E = harga_jual
          this.form.margin.harga_jual_ppn_E = harga_jual_ppn
          break
        case 'F':
          this.form.margin.margin_netto_F = margin_bersih
          this.form.margin.margin_F = margin
          this.form.margin.harga_jual_F = harga_jual
          this.form.margin.harga_jual_ppn_F = harga_jual_ppn
          break
      }
    },
    inputMarginA(e) {
      this.form.margin.margin_bruto_A = e.value
      this.hitungMargin('A')
    },
    inputMarginB(e) {
      this.form.margin.margin_bruto_B = e.value
      this.hitungMargin('B')
    },
    inputMarginC(e) {
      this.form.margin.margin_bruto_C = e.value
      this.hitungMargin('C')
    },
    inputMarginD(e) {
      this.form.margin.margin_bruto_D = e.value
      this.hitungMargin('D')
    },
    inputMarginE(e) {
      this.form.margin.margin_bruto_E = e.value
      this.hitungMargin('E')
    },
    inputMarginF(e) {
      this.form.margin.margin_bruto_F = e.value
      this.hitungMargin('F')
    },
    previewProduk1(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.fotoProduk1 = e.target.result
        }
        this.fileFotoProduk1 = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    previewProduk2(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.fotoProduk2 = e.target.result
        }
        this.fileFotoProduk2 = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    previewProduk3(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.fotoProduk3 = e.target.result
        }
        this.fileFotoProduk3 = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    updateImages() {
      const tempImagesForm = Object.assign({}, this.form)
      if (this.form.images != null) {
        for (let i = 0; i < this.form.images.length; i++) {
          const image = this.form.images[i]
          tempImagesForm.images[i] = image
            .split('/')
            .pop()
            .split('#')[0]
            .split('?')[0]
        }
      } else {
        tempImagesForm.images = []
      }

      const deletedImagesForm = Object.assign({}, tempImagesForm)
      const uploadImagesForm = Object.assign({}, tempImagesForm)

      deletedImagesForm.images = []
      uploadImagesForm.images = []

      if (this.fileFotoProduk1 != null) {
        if (tempImagesForm.images[0] != null) {
          deletedImagesForm.images.push(tempImagesForm.images[0])
        }
        uploadImagesForm.images.push(this.fileFotoProduk1)
      }
      if (this.fileFotoProduk2 != null) {
        if (tempImagesForm.images[1] != null) {
          deletedImagesForm.images.push(tempImagesForm.images[1])
        }
        uploadImagesForm.images.push(this.fileFotoProduk2)
      }
      if (this.fileFotoProduk3 != null) {
        if (tempImagesForm.images[2] != null) {
          deletedImagesForm.images.push(tempImagesForm.images[2])
        }
        uploadImagesForm.images.push(this.fileFotoProduk3)
      }

      if (deletedImagesForm.images.length > 0) {
        this.obatService.deleteImage(this.form.id, deletedImagesForm.images)
      }
      if (uploadImagesForm.images.length > 0) {
        this.obatService.uploadImage(this.form.id, uploadImagesForm.images)
      }
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.isLoadingSave = true

      if (this.fileFotoProduk1 && this.fileFotoProduk1.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Foto',
          detail: 'Ukuran file produk #1 melebihi 5 MB',
          life: 3000,
        })
        return
      }

      if (this.fileFotoProduk2 && this.fileFotoProduk2.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Foto',
          detail: 'Ukuran file produk #2 melebihi 5 MB',
          life: 3000,
        })
        return
      }

      if (this.fileFotoProduk3 && this.fileFotoProduk3.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Foto',
          detail: 'Ukuran file produk #3 melebihi 5 MB',
          life: 3000,
        })
        return
      }
      this.submitted = true

      const newData = Object.assign({}, this.form)

      /*
      if (newData.pabrik) {
        newData.pabrik_id = newData.pabrik.id
      }
      if (newData.supplier) {
        newData.supplier_id = newData.supplier.id
      }
      if (newData.golongan) {
        newData.golongan_id = newData.golongan.id
      }
      */
      if (newData.komposisi) {
        newData.komposisi_id = newData.komposisi.id
      }
      if (newData.satuan) {
        let newSatuan = []
        newData.satuan.forEach((item) => {
          newSatuan.push(item.id)
        })
        newData.satuan = newSatuan
      }
      if (newData.satuan_tkcl) {
        newData.satuan_tkcl_id = newData.satuan_tkcl.id
      }

      delete newData.pabrik
      delete newData.supplier
      delete newData.golongan
      delete newData.komposisi
      delete newData.satuan_tkcl
      delete newData.images

      if (this.id > 0) {
        this.obatService
          .update(newData)
          .then((res) => {
            if (res.data.status === 200) {
              this.updateImages()
              this.$toast.add({
                severity: 'success',
                summary: 'Data obat',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data obat', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.obatService
          .add(newData)
          .then((res) => {
            if (res.data.status === 200) {
              this.form.id = res.data.data
              this.updateImages()
              this.$toast.add({
                severity: 'success',
                summary: 'Data obat',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'Obat' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data obat', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
  },
  validations() {
    return {
      form: {
        supplier_id: {
          required: helpers.withMessage('Supplier harus diisi.', required),
          minValue: minValue(1), // Use the minValue validator with a minimum value of 1
        },
        pabrik_id: {
          required: helpers.withMessage('Pabrik harus diisi.', required),
          minValue: minValue(1), // Use the minValue validator with a minimum value of 1
        },
        golongan_id: {
          required: helpers.withMessage('Golongan obat harus diisi.', required),
          minValue: minValue(1), // Use the minValue validator with a minimum value of 1
        },
        satuan_tkcl: {
          required: helpers.withMessage(
            'Satuan terkecil obat harus diisi.',
            required
          ),
        },
        nama: {
          required: helpers.withMessage('Nama obat harus diisi.', required),
        },
        stok_minimum: {
          required: helpers.withMessage('Stok minimum harus diisi.', required),
        },
      },
    }
  },
}
</script>
