<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12">
          <label
            :class="{
              'p-error': v$.form.pabrik_id.$invalid && submitted,
            }"
            >Pabrik *</label
          >
          <Dropdown
            v-model="form.pabrik_id"
            :filter="true"
            :options="listPabrik"
            :class="{
              'p-invalid': v$.form.pabrik_id.$invalid && submitted,
            }"
            optionValue="id"
            optionLabel="nama"
            placeholder="Pilih pabrik"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.pabrik_id.$invalid && submitted) ||
              v$.form.pabrik_id.$pending.$response
            "
            class="p-error"
            >{{ v$.form.pabrik_id.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.margin_bruto_A.$invalid && submitted,
            }"
            >Margin A (%) *</label
          >
          <InputNumber
            v-model="form.margin_bruto_A"
            :class="{
              'p-invalid': v$.form.margin_bruto_A.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
            :minFractionDigits="2"
            mode="decimal"
            locale="id-ID"
          />
          <small
            v-if="
              (v$.form.margin_bruto_A.$invalid && submitted) ||
              v$.form.margin_bruto_A.$pending.$response
            "
            class="p-error"
            >{{ v$.form.margin_bruto_A.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.margin_bruto_A.$invalid && submitted,
            }"
            >Margin B (%) *</label
          >
          <InputNumber
            v-model="form.margin_bruto_B"
            :minFractionDigits="2"
            :class="{
              'p-invalid': v$.form.margin_bruto_B.$invalid && submitted,
            }"
            mode="decimal"
            class="w-full"
            input-class="w-full"
            locale="id-ID"
          />
          <small
            v-if="
              (v$.form.margin_bruto_B.$invalid && submitted) ||
              v$.form.margin_bruto_B.$pending.$response
            "
            class="p-error"
            >{{ v$.form.margin_bruto_B.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.margin_bruto_C.$invalid && submitted,
            }"
            >Margin C (%) *</label
          >
          <InputNumber
            v-model="form.margin_bruto_C"
            :minFractionDigits="2"
            :class="{
              'p-invalid': v$.form.margin_bruto_C.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
            mode="decimal"
            locale="id-ID"
          />
          <small
            v-if="
              (v$.form.margin_bruto_C.$invalid && submitted) ||
              v$.form.margin_bruto_C.$pending.$response
            "
            class="p-error"
            >{{ v$.form.margin_bruto_C.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.margin_bruto_D.$invalid && submitted,
            }"
            >Margin D (%) *</label
          >
          <InputNumber
            v-model="form.margin_bruto_D"
            mode="decimal"
            :minFractionDigits="2"
            locale="id-ID"
            :class="{
              'p-invalid': v$.form.margin_bruto_D.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.margin_bruto_D.$invalid && submitted) ||
              v$.form.margin_bruto_D.$pending.$response
            "
            class="p-error"
            >{{ v$.form.margin_bruto_D.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.margin_bruto_E.$invalid && submitted,
            }"
            >Margin E (%) *</label
          >
          <InputNumber
            v-model="form.margin_bruto_E"
            mode="decimal"
            :minFractionDigits="2"
            locale="id-ID"
            :class="{
              'p-invalid': v$.form.margin_bruto_E.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.margin_bruto_E.$invalid && submitted) ||
              v$.form.margin_bruto_E.$pending.$response
            "
            class="p-error"
            >{{ v$.form.margin_bruto_E.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.margin_bruto_F.$invalid && submitted,
            }"
            >Margin F (%) *</label
          >
          <InputNumber
            v-model="form.margin_bruto_F"
            mode="decimal"
            :minFractionDigits="2"
            locale="id-ID"
            :class="{
              'p-invalid': v$.form.margin_bruto_F.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.margin_bruto_F.$invalid && submitted) ||
              v$.form.margin_bruto_F.$pending.$response
            "
            class="p-error"
            >{{ v$.form.margin_bruto_F.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-4">
          <label
            :class="{
              'p-error': v$.form.margin_at.$invalid && submitted,
            }"
            >Jadwal eksekusi</label
          >
          <Calendar
            v-model="form.margin_at"
            :showTime="true"
            :showSeconds="true"
            :class="{
              'p-invalid': v$.form.margin_at.$invalid && submitted,
            }"
            dateFormat="dd/mm/yy"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.margin_at.$invalid && submitted) ||
              v$.form.margin_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.margin_at.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    listPabrik: {
      type: Array,
      default() {
        return []
      },
    },
    item: {
      type: Object,
      default() {
        return {
          id: 0,
          pabrik_id: null,
          margin_bruto_A: 0,
          margin_bruto_B: 0,
          margin_bruto_C: 0,
          margin_bruto_D: 0,
          margin_bruto_E: 0,
          margin_bruto_F: 0,
          margin_at: null,
        }
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const form = Object.assign({}, this.form)
      form.margin_at = dayjs(this.form.margin_at).format('YYYY-MM-DD HH:mm:ss')
      this.$emit('save', form)
    },
  },
  validations() {
    return {
      form: {
        pabrik_id: {
          required: helpers.withMessage('Pabrik harus diisi.', required),
        },
        margin_at: {
          required: helpers.withMessage(
            'Waktu eksekusi harus diisi.',
            required
          ),
        },
        margin_bruto_A: {
          required: helpers.withMessage(
            'Nilai margin A harus diisi.',
            required
          ),
        },
        margin_bruto_B: {
          required: helpers.withMessage(
            'Nilai margin B harus diisi.',
            required
          ),
        },
        margin_bruto_C: {
          required: helpers.withMessage(
            'Nilai margin C harus diisi.',
            required
          ),
        },
        margin_bruto_D: {
          required: helpers.withMessage(
            'Nilai margin D harus diisi.',
            required
          ),
        },
        margin_bruto_E: {
          required: helpers.withMessage(
            'Nilai margin E harus diisi.',
            required
          ),
        },
        margin_bruto_F: {
          required: helpers.withMessage(
            'Nilai margin F harus diisi.',
            required
          ),
        },
      },
    }
  },
}
</script>
