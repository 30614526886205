<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Supplier</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Supplier / Data Supplier</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Supplier"
          class="mr-2"
          @click="onAddSupplier"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="mr-2 p-button-outlined"
          @click="onExport()"
        />
        <Button
          icon="pi pi-download"
          class="p-button-outlined"
          label="Download form spesimen"
          @click="onDownloadFormSpesimen"
        />
      </div>
      <grid-supplier
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onEditSupplier"
        @delete="onConfirmDeleteSupplier"
        @download="onDownloadSpesimen"
      />
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit supplier' : 'Tambah supplier'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-supplier
        :item="form"
        :loading="isLoadingSave"
        :kelompok-supplier="kelompokSupplier"
        @save="onSaveSupplier"
      />
    </Dialog>
    <Dialog
      header="Hapus Supplier"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Supplier <strong>{{ form.nama }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteSupplier"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SupplierService from '@/services/SupplierService'
import KelompokSupplierService from '@/services/KelompokSupplierService'
import FormSupplier from '@/components/master/FormSupplier'
import GridSupplier from '@/components/master/GridSupplier'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'

export default {
  name: 'Supplier',
  components: {
    FormSupplier,
    GridSupplier,
    Hotkey,
  },
  data() {
    return {
      supplierService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
      kelompokSupplier: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'nama',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.supplierService = new SupplierService()
  },
  mounted() {
    this.loadGridData()
    const kelompokSupplierService = new KelompokSupplierService()
    kelompokSupplierService
      .get()
      .then((res) => {
        this.kelompokSupplier = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Kelompok Supplier', this)
      })
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.supplierService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          /* const data = res.data.data
          const listStatusPajak = [
            { kode: 'pkp', nama: 'PKP' },
            { kode: 'non-pkp', nama: 'Non PKP' },
          ]
          data.forEach((item) => {
            if (item.status_pajak.length > 0) {
              item.status_pajak = item.status_pajak.toLowerCase()
              item['status_pajak_text'] = listStatusPajak.find(
                (x) => x.kode === item.status_pajak
              ).nama
            } else {
              item['status_pajak_text'] = ''
            }
          })
          this.items = data */
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditSupplier(data) {
      data.izin_validity = data.izin_validity
        ? new Date(data.izin_validity)
        : null

      data.cdob_validity = data.cdob_validity
        ? new Date(data.cdob_validity)
        : null

      if (data.apoteker) {
        data.apoteker.sipa_validity = data.apoteker.sipa_validity
          ? new Date(data.apoteker.sipa_validity)
          : null

        data.apoteker.stra_validity = data.apoteker.stra_validity
          ? new Date(data.apoteker.stra_validity)
          : null

        data.apoteker.tanggal_lahir = data.apoteker.tanggal_lahir
          ? new Date(data.apoteker.tanggal_lahir)
          : null
      }

      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onAddSupplier() {
      this.form = {}
      this.form.id = 0
      this.form.kode_bpom = ''
      this.form.nama = ''
      this.form.nama_alias = ''
      this.form.kontak = ''
      this.form.alamat = ''
      this.form.kota = ''
      this.form.kode_pos = ''
      this.form.telepon_1 = ''
      this.form.telepon_2 = ''
      this.form.fax = ''
      this.form.npwp = ''
      this.form.status_pajak = 'pkp'
      this.form.no_izin = ''
      this.form.izin_validity = ''
      this.form.no_cdob = ''
      this.form.cdob_validity = ''
      this.form.email = ''
      this.form.spesimen = ''
      this.form.top = 0
      this.form.apoteker = {
        nama: '',
        alamat: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        telepon: '',
        no_sipa: '',
        sipa_validity: '',
        no_stra: '',
        stra_validity: '',
      }
      this.dialog = true
    },
    onSaveSupplier(data, file) {
      this.isLoadingSave = true
      if (data && data.id === 0) {
        const newData = Object.assign({}, data)
        delete newData.spesimen
        // add new
        this.supplierService
          .add(newData)
          .then((res) => {
            if (res.data.status === 200) {
              if (file != null) {
                this.uploadFile(newData.id, file)
              } else {
                this.loadGridData()
              }
              this.$toast.add({
                severity: 'success',
                summary: 'Data Supplier',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Supplier', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        const newData = Object.assign({}, data)
        delete newData.spesimen
        // edit
        this.supplierService
          .update(newData)
          .then((res) => {
            if (res.data.status === 200) {
              if (file != null) {
                this.uploadFile(newData.id, file)
              } else {
                this.loadGridData()
              }
              this.$toast.add({
                severity: 'success',
                summary: 'Data Supplier',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Supplier', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeleteSupplier(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteSupplier() {
      this.supplierService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Supplier',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Supplier', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onDownloadSpesimen(data) {
      const link = document.createElement('a')
      link.href = data.spesimen
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
    },
    onDownloadFormSpesimen() {
      this.supplierService
        .downloadFormSpesimen()
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Data Supplier',
              detail: 'Gagal mengunduh spesimen.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'spesimen-supplier.pdf')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Download spesimen', this)
        })
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddSupplier()
      }
    },
    uploadFile(id, value) {
      if (value) {
        this.supplierService
          .upload(id, value)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data supplier',
                detail: 'Spesimen berhasil diupload.',
                life: 3000,
              })
              this.loadGridData()
            }
          })
          .catch((err) => {
            errorHandler(err, 'Upload spesimen', this)
          })
      }
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.supplierService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-supplier.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Supplier', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
  },
}
</script>
