import http from '../clients/Axios'

export default class ProfileService {
  // Identitas Perusahaan
  async get() {
    return await http.get('/profile')
  }

  // Update identitas perusahaan
  async update(form) {
    return await http.post('/profile', form)
  }

  // User profile
  async getUserProfile() {
    return await http.get('/user/index')
  }

  // Update identitas perusahaan
  async updateUserProfile(form) {
    return await http.post('/user/index', form)
  }

  // Update identitas perusahaan
  async updateRekening(rekening) {
    return await http.post(`/profile/rekening/${rekening.id}`, rekening)
  }

  // Update identitas perusahaan
  async deleteRekening(id) {
    return await http.delete(`/profile/rekening/${id}`)
  }

  // Update identitas perusahaan
  async addRekening(rekening) {
    return await http.post('/profile/rekening', rekening)
  }
}
