<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl" v-if="id > 0">Edit Obat</span>
      <span class="pr-2 mr-2 font-bold text-2xl" v-else>Tambah Obat</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small" v-if="id > 0">/ Master / Obat / Edit</span>
        <span class="font-small" v-else>/ Master / Obat / Tambah</span>
      </div>
    </div>
    <div class="card bg-white">
      <form-obat :id="id" />
    </div>
  </div>
</template>

<script>
import FormObat from '@/components/master/FormObat'

export default {
  components: {
    FormObat,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
    }
  },
}
</script>
