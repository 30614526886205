<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12 md:col-7">
          <div class="formgrid grid">
            <div class="field col-12">
              <label
                :class="{
                  'p-error': v$.form.salesman_id.$invalid && submitted,
                }"
                >Salesman *</label
              >
              <Dropdown
                v-model="form.salesman_id"
                :options="salesman"
                optionValue="id"
                optionLabel="nama"
                :class="{
                  'p-invalid': v$.form.salesman_id.$invalid && submitted,
                }"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.salesman_id.$invalid && submitted) ||
                  v$.form.salesman_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.salesman_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-3">
              <label
                :class="{
                  'p-error': v$.form.status_pembayaran.$invalid && submitted,
                }"
                >Pembayaran *</label
              >
              <Dropdown
                v-model="form.status_pembayaran"
                optionValue="value"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.form.status_pembayaran.$invalid && submitted,
                }"
                :options="[
                  { value: 'tunai', label: 'TUNAI' },
                  { value: 'kredit', label: 'KREDIT' },
                ]"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.status_pembayaran.$invalid && submitted) ||
                  v$.form.status_pembayaran.$pending.$response
                "
                class="p-error"
                >{{ v$.form.status_pembayaran.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-9">
              <label
                :class="{
                  'p-error': v$.form.kelompok_id.$invalid && submitted,
                }"
                >Kelompok *</label
              >
              <Dropdown
                v-model="form.kelompok_id"
                :options="kelompokPelanggan"
                optionValue="id"
                optionLabel="keterangan"
                :class="{
                  'p-invalid': v$.form.kelompok_id.$invalid && submitted,
                }"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.kelompok_id.$invalid && submitted) ||
                  v$.form.kelompok_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.kelompok_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-9 py-0">
              <label :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
                >Nama pelanggan *</label
              >
              <InputText
                v-model="form.nama"
                :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
                type="text"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama.$invalid && submitted) ||
                  v$.form.nama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-3">
              <label>Alias</label>
              <InputText v-model="form.nama_alias" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-12">
              <label
                :class="{ 'p-error': v$.form.nama_pajak.$invalid && submitted }"
                >Nama pajak *</label
              >
              <InputText
                v-model="form.nama_pajak"
                :class="{
                  'p-invalid': v$.form.nama_pajak.$invalid && submitted,
                }"
                type="text"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama_pajak.$invalid && submitted) ||
                  v$.form.nama_pajak.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama_pajak.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Nama apoteker</label>
              <InputText
                v-model="form.nama_apoteker"
                type="text"
                class="w-full"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label
                :class="{ 'p-error': v$.form.area_id.$invalid && submitted }"
                >Area *</label
              >
              <Dropdown
                v-model="form.area_id"
                :options="areaPelanggan"
                optionValue="id"
                optionLabel="nama"
                :class="{
                  'p-invalid': v$.form.area_id.$invalid && submitted,
                }"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.area_id.$invalid && submitted) ||
                  v$.form.area_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.area_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Kode Harga</label>
              <Dropdown
                v-model="form.kode_harga"
                optionValue="kode"
                optionLabel="nama"
                :options="[
                  { kode: 'A', nama: 'Harga A' },
                  { kode: 'B', nama: 'Harga B' },
                  { kode: 'C', nama: 'Harga C' },
                  { kode: 'D', nama: 'Harga D' },
                  { kode: 'E', nama: 'Harga E' },
                  { kode: 'F', nama: 'Harga F' },
                ]"
                class="w-full"
              />
            </div>
            <div class="field col-12">
              <label>Alamat Pajak</label>
              <InputText v-model="form.alamat" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label>Kota</label>
              <InputText v-model="form.kota" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label>Kode pos</label>
              <InputText v-model="form.kode_pos" type="text" class="w-full" />
            </div>
            <div class="field col-12">
              <label>Koordinat Google Map</label>
              <div class="p-inputgroup">
                <InputText v-model="form.gmap" type="text" class="w-full" />
                <Button
                  icon="pi pi-external-link"
                  class="p-button-secondary"
                  @click="openGmap"
                />
              </div>
            </div>
            <div class="field col-12">
              <label>Alamat pengiriman</label>
              <InputText
                v-model="form.pengiriman_alamat"
                type="text"
                class="w-full"
              />
            </div>
            <div class="field col-12">
              <label
                :class="{
                  'p-error': v$.form.pengiriman_penerima.$invalid && submitted,
                }"
                >Nama Penerima</label
              >
              <InputText
                v-model="form.pengiriman_penerima"
                type="text"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.pengiriman_penerima.$invalid && submitted) ||
                  v$.form.pengiriman_penerima.$pending.$response
                "
                class="p-error"
                >{{ v$.form.pengiriman_penerima.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Kota</label>
              <InputText
                v-model="form.pengiriman_kota"
                type="text"
                class="w-full"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>Kode pos</label>
              <InputText
                v-model="form.pengiriman_kode_pos"
                type="text"
                class="w-full"
              />
            </div>
            <div class="field col-12">
              <label>Koordinat Google Map</label>
              <div class="p-inputgroup">
                <InputText
                  v-model="form.pengiriman_gmap"
                  type="text"
                  class="w-full"
                />
                <Button
                  icon="pi pi-external-link"
                  class="p-button-secondary"
                  @click="openPengirimanGmap"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label>Telepon (1)</label>
              <InputText v-model="form.telepon_1" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label>Telepon (2)</label>
              <InputText v-model="form.telepon_2" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label>Fax</label>
              <InputText v-model="form.fax" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label>Email</label>
              <InputText v-model="form.email" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label :class="{ 'p-error': v$.form.npwp.$invalid && submitted }"
                >NPWP *</label
              >
              <InputText
                v-model="form.npwp"
                :class="{
                  'p-invalid': v$.form.npwp.$invalid && submitted,
                }"
                type="text"
                class="w-full"
              />
              <small
                v-if="
                  (v$.form.npwp.$invalid && submitted) ||
                  v$.form.npwp.$pending.$response
                "
                class="p-error"
                >{{ v$.form.npwp.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Status Pajak</label>
              <Dropdown
                v-model="form.status_pajak"
                optionValue="kode"
                optionLabel="nama"
                :options="[
                  { kode: 'pkp', nama: 'PKP' },
                  { kode: 'non-pkp', nama: 'Non PKP' },
                ]"
                class="w-full"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>NPPKP</label>
              <InputText v-model="form.nppkp" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label>T.O.P Faktur</label>
              <div class="p-inputgroup">
                <InputNumber
                  :min="0"
                  :max="31"
                  v-model="form.top"
                  locale="id-ID"
                  class="w-full"
                />
                <span class="p-inputgroup-addon">hari</span>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label>Batas kredit</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Rp</span>
                <InputNumber
                  :min="0"
                  v-model="form.batas_kredit"
                  locale="id-ID"
                  mode="decimal"
                  class="w-full"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label>Kode E-Report</label>
              <InputText
                v-model="form.kode_ereport"
                type="text"
                class="w-full"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>No SIA</label>
              <InputText v-model="form.no_sia" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label
                :class="{
                  'p-error': v$.form.sia_validity.$invalid && submitted,
                }"
                >Masa berlaku SIA</label
              >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-calendar"></i
                ></span>
                <Calendar
                  v-model="form.sia_validity"
                  :class="{
                    'p-invalid': v$.form.sia_validity.$invalid && submitted,
                  }"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="yearRangeExpiry"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <small
                v-if="
                  (v$.form.sia_validity.$invalid && submitted) ||
                  v$.form.sia_validity.$pending.$response
                "
                class="p-error"
                >{{ v$.form.sia_validity.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>No SIPA</label>
              <InputText v-model="form.no_sipa" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label
                :class="{
                  'p-error': v$.form.sipa_validity.$invalid && submitted,
                }"
                >Masa berlaku SIPA</label
              >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-calendar"></i
                ></span>
                <Calendar
                  v-model="form.sipa_validity"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="yearRangeExpiry"
                  :class="{
                    'p-invalid': v$.form.sipa_validity.$invalid && submitted,
                  }"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <small
                v-if="
                  (v$.form.sipa_validity.$invalid && submitted) ||
                  v$.form.sipa_validity.$pending.$response
                "
                class="p-error"
                >{{ v$.form.sipa_validity.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>NO CDOB</label>
              <InputText v-model="form.no_cdob" type="text" class="w-full" />
            </div>
            <div class="field col-12 md:col-6">
              <label>Masa berlaku CDOB</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-calendar"></i
                ></span>
                <Calendar
                  v-model="form.cdob_validity"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="yearRangeExpiry"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label>Status tempat</label>
              <Dropdown
                v-model="form.status_tempat"
                optionValue="kode"
                optionLabel="nama"
                :options="[
                  { kode: 'sewa', nama: 'Sewa' },
                  { kode: 'hak-milik', nama: 'Hak Milik' },
                ]"
                class="w-full"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>Masa akhir sewa</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-calendar"></i
                ></span>
                <Calendar
                  v-model="form.sewa_validity"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="yearRangeExpiry"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                optionValue="key"
                optionLabel="label"
                :options="[
                  { key: 'aktif', label: 'Aktif' },
                  { key: 'non-aktif', label: 'Non Aktif' },
                ]"
                class="w-full"
                input-class="w-full"
              />
            </div>
          </div>
        </div>
        <div class="hidden md:block"><Divider layout="vertical" /></div>
        <div class="col">
          <div class="field">
            <label>Foto KTP Owner</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewKtpPemilik"
              maxlength="5000000"
            />
            <template v-if="ktpPemilik">
              <div
                style="width: 100%; text-align: center; padding: 10px 2px"
                @click="showPreviewImage"
              >
                <img
                  :src="ktpPemilik"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px"
                />
              </div>
            </template>
          </div>
          <div class="field">
            <label>Foto Apoteker</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewKtpApoteker"
              maxlength="5000000"
            />
            <template v-if="ktpApoteker">
              <div
                style="width: 100%; text-align: center; padding: 10px 2px"
                @click="showPreviewImage"
              >
                <img
                  :src="ktpApoteker"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px; margin: auto"
                />
              </div>
            </template>
          </div>
          <div class="field">
            <label>Foto Gedung</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewFotoBangunan"
              maxlength="5000000"
            />
            <template v-if="fotoBangunan">
              <div
                style="width: 100%; text-align: center; padding: 10px 2px"
                @click="showPreviewImage"
              >
                <img
                  :src="fotoBangunan"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px"
                />
              </div>
            </template>
          </div>
          <div class="field">
            <label>Spesimen</label>
            <InputText
              type="file"
              class="w-full"
              accept="application/pdf,image/*"
              @change="setSpesimen"
              maxlength="5000000"
            />
            <template v-if="spesimen_ext !== '' && spesimen_ext === 'pdf'">
              <div style="width: 100%; text-align: center; padding: 10px 2px">
                <!--
                <embed
                  :src="spesimen"
                  style="width: calc(100%); height: 300px; margin: auto"
                />
                -->
                <a class="p-button-outlined" target="_blank" :href="spesimen">
                  <Button
                    icon="pi pi-eye"
                    class="p-button-outlined"
                    label="Lihat"
                  />
                </a>
              </div>
            </template>
            <template v-else>
              <div
                style="width: 100%; text-align: center; padding: 10px 2px"
                @click="showPreviewImage"
              >
                <img
                  :src="spesimen"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>

    <Dialog
      header="Preview"
      v-model:visible="preview"
      :modal="true"
      :maximizable="true"
    >
      <form-preview-image :item="{ src: previewImage }" />
    </Dialog>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'
import FormPreviewImage from '@/components/master/FormPreviewImage'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    FormPreviewImage,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    kelompokPelanggan: {
      type: Array,
      default() {
        return []
      },
    },
    areaPelanggan: {
      type: Array,
      default() {
        return []
      },
    },
    salesman: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      ktpPemilik: this.item.ktp_pemilik,
      ktpApoteker: this.item.ktp_apoteker,
      fotoBangunan: this.item.foto_bangunan,
      itemSalesman: this.item.salesman,
      itemArea: this.item.area,
      fileKtpPemilik: null,
      fileKtpApoteker: null,
      fileFotoBangunan: null,
      spesimen: this.item.spesimen,
      spesimen_ext: this.item.spesimen
        ? this.item.spesimen.split('.').pop()
        : '',
      fileSpesimen: null,
      yearRangeExpiry: dayjs().year() + ':' + (parseInt(dayjs().year()) + 15),
      previewImage: '',
      preview: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      this.form.sia_validity = this.form.sia_validity
        ? dayjs(this.form.sia_validity).format('YYYY-MM-DD')
        : null

      this.form.sipa_validity = this.form.sipa_validity
        ? dayjs(this.form.sipa_validity).format('YYYY-MM-DD')
        : null

      this.form.sewa_validity = this.form.sewa_validity
        ? dayjs(this.form.sewa_validity).format('YYYY-MM-DD')
        : null

      this.form.cdob_validity = this.form.cdob_validity
        ? dayjs(this.form.cdob_validity).format('YYYY-MM-DD')
        : null

      if (!isFormValid) {
        return
      }

      if (this.fileKtpPemilik && this.fileKtpPemilik.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Foto',
          detail: 'Ukuran file melebihi 5 MB',
          life: 3000,
        })
        return
      }

      if (this.fileKtpApoteker && this.fileKtpApoteker.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Foto',
          detail: 'Ukuran file melebihi 5 MB',
          life: 3000,
        })
        return
      }

      if (this.fileFotoBangunan && this.fileFotoBangunan.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Foto',
          detail: 'Ukuran file melebihi 5 MB',
          life: 3000,
        })
        return
      }

      if (this.fileSpesimen && this.fileSpesimen.size > 5000000) {
        this.$toast.add({
          severity: 'error',
          summary: 'Spesimen',
          detail: 'Ukuran file Spesimen melebihi 5 MB',
          life: 3000,
        })
        return
      }

      this.$emit(
        'save',
        this.form,
        this.fileKtpPemilik,
        this.fileKtpApoteker,
        this.fileFotoBangunan,
        this.fileSpesimen
      )
    },
    openGmap() {
      var url = this.item.gmap
      open(url, function (err) {
        if (err) throw err
      })
    },
    openPengirimanGmap() {
      var url = this.item.pengiriman_gmap
      open(url, function (err) {
        if (err) throw err
      })
    },
    previewKtpPemilik(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.ktpPemilik = e.target.result
        }
        this.fileKtpPemilik = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    previewKtpApoteker(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.ktpApoteker = e.target.result
        }
        this.fileKtpApoteker = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    previewFotoBangunan(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.fotoBangunan = e.target.result
        }
        this.fileFotoBangunan = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    setSpesimen(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.spesimen = e.target.result
        }
        this.fileSpesimen = event.target.files[0]
        let name = this.fileSpesimen.name
        this.spesimen_ext = name.split('.').pop()
        console.log('spesimen_ext', this.spesimen_ext)
        reader.readAsDataURL(input.files[0])
      }
    },
    showPreviewImage(event) {
      let image = event.srcElement.attributes.src.value
      if (image) {
        this.previewImage = image
        this.preview = true
      }
    },
  },
  validations() {
    return {
      form: {
        kelompok_id: {
          required: helpers.withMessage('Kelompok harus diisi.', required),
        },
        area_id: {
          required: helpers.withMessage('Area harus diisi.', required),
        },
        salesman_id: {
          required: helpers.withMessage('Salesman harus diisi.', required),
        },
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        nama_pajak: {
          required: helpers.withMessage('Nama pajak harus diisi.', required),
        },
        pengiriman_penerima: {
          required: helpers.withMessage(
            'Nama penerima harus diisi.',
            required
          ),
        },
        npwp: {
          required: helpers.withMessage('NPWP harus diisi.', required),
        },
        sia_validity: {
          required: helpers.withMessage(
            'Tanggal berlaku SIA harus diisi.',
            required
          ),
        },
        sipa_validity: {
          required: helpers.withMessage(
            'Tanggal berlaku SIPA harus diisi.',
            required
          ),
        },
        status_pembayaran: {
          required: helpers.withMessage(
            'Status pembayaran harus diisi.',
            required
          ),
        },
      },
    }
  },
}
</script>
