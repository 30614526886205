<template>
  <DataTable
    :value="items"
    :loading="loading"
    :row-hover="true"
    class="p-datatable-sm"
  >
    <Column field="key" headerClass="hidden">
      <template #body="{ data }">
        <div class="font-bold">{{ data.name.toUpperCase() }}</div>
        <div v-if="data.description" class="font-italic text-500">
          {{ data.description }}
        </div>
      </template>
    </Column>
    <Column field="value" headerClass="hidden">
      <template #body="{ data }">
        <!--
        <InputSwitch
          v-if="data.type === 'boolean'"
          v-model="data.value"
          :true-value="true"
          :false-value="false"
          :readonly="true"
          disabled
        />
        -->
        <div v-if="data.type === 'boolean'">
          <div v-if="data.value === true">Ya</div>
          <div v-else>Tidak</div>
        </div>
        <div v-else>{{ data.value }}</div>
      </template>
    </Column>
    <Column headerClass="hidden" style="width: 200px">
      <template #body="{ data }">
        <div class="flex justify-content-end align-items-end">
          <Button
            type="button"
            label="Update"
            class="p-button-link"
            @click="$emit('edit', data)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        value: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
}
</script>
