<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Pabrik</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Pabrik</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Pabrik"
          class="mr-2"
          @click="onAddPabrik()"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined"
          @click="onExport"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
      >
        <template #columns>
          <Column
            field="kode"
            header="Kode Pabrik"
            style="width: 170px"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.kode }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="kode_kemenkes"
            header="E-Report"
            style="width: 170px"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.kode_kemenkes }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="nama" header="Nama Pabrik" :sortable="true">
            <template #body="{ data }">
              {{ data.nama }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="alamat" header="Alamat" />
          <Column field="status_text" header="Status" />
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit pabrik' : 'Tambah pabrik'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-pabrik :item="form" :loading="isLoadingSave" @save="onSavePabrik" />
    </Dialog>
    <Dialog
      header="Hapus pabrik"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Pabrik <strong>{{ form.nama }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeletePabrik"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import errorHandler from '@/helpers/error-handler'
import FormPabrik from '@/components/master/FormPabrik'
import FxTable from '@/components/FxTable'
import Hotkey from '@/components/Hotkey'
import PabrikService from '@/services/PabrikService'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Pabrik',
  components: {
    FormPabrik,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      pabrikService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
      initialValue: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'nama',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kode_kemenkes: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.pabrikService = new PabrikService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.pabrikService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          const data = res.data.data
          const listStatus = [
            { kode: 'aktif', nama: 'Aktif' },
            { kode: 'non-aktif', nama: 'Non Aktif' },
          ]
          data.forEach((item) => {
            item.status = item.status.toLowerCase()
            item['status_text'] = listStatus.find(
              (x) => x.kode === item.status
            ).nama
          })
          this.items = data
          this.totalRecords = res.data.total
        })
        .catch((err) => {
          errorHandler(err, 'Data Pabrik', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.pabrikService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-pabrik.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Pabrik', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.initialValue = Object.assign({}, data)
      this.dialog = true
    },
    onAddPabrik() {
      this.form = {
        id: 0,
        kode: '',
        kode_kemenkes: '',
        nama: '',
        alamat: '',
        status: 'aktif',
      }
      this.dialog = true
    },
    onSavePabrik(data) {
      this.isLoadingSave = true
      if (data && data.id === 0) {
        // add new pabrik
        this.pabrikService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Pabrik',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Pabrik', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        this.pabrikService
          .update(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Pabrik',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Pabrik', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeletePabrik() {
      this.pabrikService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Pabrik',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Pabrik', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddPabrik()
      }
    },
  },
}
</script>
