<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      filterDisplay="row"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #header>
        <div style="text-align: left">
          <MultiSelect
            :modelValue="selectedColumns"
            :options="columns"
            optionLabel="header"
            @update:modelValue="onToggle"
            placeholder="Pilih kolom tambahan"
            style="width: 20em"
          />
        </div>
      </template>
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="kode" header="Kode" style="min-width: 70px" />
      <Column
        field="kelompok.kelompok"
        header="Kelompok"
        style="min-width: 70px;"
      />
      <Column
        field="kode_kemenkes"
        header="E-Report"
        style="min-width: 70px"
      />
      <Column
        field="nama"
        header="Nama"
        :sortable="true"
        style="min-width: 260px"
      >
        <template #body="{ data }">
          {{ data.nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="alamat"
        header="Alamat"
        bodyClass="text-nowrap text-truncate"
        style="min-width: 360px; margin-left: 20px; margin-right: 20px"
      />
      <Column field="kota" header="Kota" style="min-width: 70px" />
      <Column
        field="tanggal_lahir"
        header="TGL. LAHIR"
        style="min-width: 70px"
      >
        <template #body="{ data }">
          {{ formatDate(data.apoteker, 'birth') }}
        </template>
      </Column>
      <Column
        field="sipa_validity"
        header="EXP. SIPA"
        style="min-width: 70px"
      >
        <template #body="{ data }">
          {{ formatDate(data.apoteker, 'sipa') }}
        </template>
      </Column>
      <Column
        field="stra_validity"
        header="EXP. STRA"
        style="min-width: 70px"
      >
        <template #body="{ data }">
          {{ formatDate(data.apoteker, 'stra') }}
        </template>
      </Column>
      <Column field="status" header="Status">
            <template #body="{ data }">
              <div v-if="data.status === 'aktif'">Aktif</div>
              <div v-else>Non Aktif</div>
            </template>
          </Column>
      <Column
        v-for="(col, index) of selectedColumns"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        style="min-width: 100px"
      />
      <Column style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-download"
            class="mr-2 p-button-outlined p-button-secondary p-button-text"
            v-tooltip.top="'Download spesimen'"
            :disabled="data.spesimen.length === 0"
            @click="$emit('download', data)"
          />
          <Button
            type="button"
            icon="pi pi-pencil"
            class="mr-2 p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <div class="text-xl">Apoteker {{ slotProps.data.nama }}</div>
          <div v-if="slotProps.data.apoteker" class="grid mt-4">
            <div class="col-5">
              <div class="formgrid grid">
                <div class="field col-12">
                  <label>Nama</label>
                  <InputText
                    v-model="slotProps.data.apoteker.nama"
                    type="text"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-12">
                  <label>Alamat</label>
                  <InputText
                    v-model="slotProps.data.apoteker.alamat"
                    type="text"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-4">
                  <label>Tempat lahir</label>
                  <InputText
                    v-model="slotProps.data.apoteker.tempat_lahir"
                    type="text"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-4">
                  <label>Tanggal Lahir</label>
                  <InputText
                    v-model="slotProps.data.apoteker.tanggal_lahir"
                    type="date"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-4">
                  <label>Telepon</label>
                  <InputText
                    v-model="slotProps.data.apoteker.telepon"
                    type="text"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-6">
                  <label>No SIPA</label>
                  <InputText
                    v-model="slotProps.data.apoteker.no_sipa"
                    type="text"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-6">
                  <label>Masa berlaku SIPA</label>
                  <InputText
                    v-model="slotProps.data.apoteker.sipa_validity"
                    type="date"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-6">
                  <label>No STRA</label>
                  <InputText
                    v-model="slotProps.data.apoteker.no_stra"
                    type="text"
                    class="w-full"
                    disabled
                  />
                </div>
                <div class="field col-6">
                  <label>Masa berlaku STRA</label>
                  <InputText
                    v-model="slotProps.data.apoteker.stra_validity"
                    type="date"
                    class="w-full"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mt-4 font-bold">Tidak ada data apoteker.</div>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      filters: {
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      selectedColumns: null,
      columns: [
        { field: 'telepon_1', header: 'Telepon 1' },
        { field: 'telepon_2', header: 'Telepon 2' },
        { field: 'hp1_1', header: 'HP 1' },
        { field: 'hp1_2', header: 'HP 2' },
        { field: 'fax', header: 'Fax' },
        { field: 'email', header: 'Email' },
        { field: 'npwp', header: 'NPWP' },
        // { field: 'status_pajak_text', header: 'Status Pajak' },
        { field: 'top', header: 'TOP' },
      ],
      expandedRows: [],
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.filters
      this.options.page = 1 // reset to page 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    },
    formatDate(value, field) {
      if (value == null) {
        return formatDate("")
      } else {
        if (field == 'birth') {
          return formatDate(value.tanggal_lahir)
        } else if (field == 'sipa') {
          return formatDate(value.sipa_validity)
        } else if (field == 'stra') {
          return formatDate(value.stra_validity)
        }
      }
    },
  },
}
</script>
