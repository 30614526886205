<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Kelompok Pelanggan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"
          >/ Master / Pelanggan / Kelompok pelanggan</span
        >
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Kelompok pelanggan"
          class="mr-2"
          @click="onAddData()"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined"
          @click="onExport()"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
      >
        <template #columns>
          <Column
            field="kelompok"
            header="Kelompok"
            style="width: 250px"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.kelompok }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                placeholder="Kode"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="keterangan" header="Keterangan" :sortable="true">
            <template #body="{ data }">
              {{ data.keterangan }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Cari kelompok"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="
        form && form.id > 0
          ? 'Edit kelompok pelanggan'
          : 'Tambah kelompok pelanggan'
      "
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-kelompok-pelanggan
        :loading="isLoadingSave"
        :item="form"
        @save="onSaveData"
      />
    </Dialog>
    <Dialog
      header="Hapus kelompok pelanggan"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Kelompok pelanggan <strong>{{ form.keterangan }}</strong> akan
          dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteData"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import errorHandler from '@/helpers/error-handler'
import FormKelompokPelanggan from '@/components/master/FormKelompokPelanggan'
import FxTable from '@/components/FxTable'
import Hotkey from '@/components/Hotkey'
import KelompokPelangganService from '@/services/KelompokPelangganService'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Data',
  components: {
    FormKelompokPelanggan,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      kelompokPelangganService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'kelompok',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        kelompok: { value: null, matchMode: FilterMatchMode.CONTAINS },
        keterangan: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.kelompokPelangganService = new KelompokPelangganService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.kelompokPelangganService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data kelompok pelanggan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onAddData() {
      this.form = {
        id: 0,
        kode: '',
        keterangan: '',
      }
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      if (data && data.id === 0) {
        // add
        this.kelompokPelangganService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data kelompok pelanggan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data kelompok pelanggan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        // edit
        this.kelompokPelangganService
          .update(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data kelompok pelanggan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data kelompok pelanggan', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteData() {
      this.kelompokPelangganService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'kelompok pelanggan',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data kelompok pelanggan', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.kelompokPelangganService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-kelompok-pelanggan.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Kelompok Pelanggan', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
  },
}
</script>
