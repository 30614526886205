<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Golongan obat</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Obat / Golongan obat</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Golongan obat"
          class="mr-2"
          @click="onAddGolonganObat()"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined"
          @click="onExport()"
        />
      </div>
      <grid-golongan-obat
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onEditData"
        @delete="onConfirmDeletion"
      />
    </div>
    <Dialog
      :header="
        form && form.id > 0 ? 'Edit Golongan obat' : 'Tambah Golongan obat'
      "
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-golongan-obat
        :loading="isLoadingSave"
        :item="form"
        :groups="groups"
        :parents="parents"
        @save="onSaveGolonganObat"
      />
    </Dialog>
    <Dialog
      header="Hapus Golongan obat"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Golongan obat <strong>{{ form.nama }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteGolonganObat"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import errorHandler from '@/helpers/error-handler'
import FormGolonganObat from '@/components/master/FormGolonganObat'
import GridGolonganObat from '@/components/master/GridGolonganObat'
import GolonganObatService from '@/services/GolonganObatService'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'GolonganObat',
  components: {
    FormGolonganObat,
    GridGolonganObat,
    Hotkey,
  },
  data() {
    return {
      golonganObatService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      parents: [],
      groups: [
        { id: 'NKT', nama: 'NKT' },
        { id: 'PSI', nama: 'PSI' },
        { id: 'OOT', nama: 'OOT' },
        { id: 'PKR', nama: 'PKR' },
        { id: 'REG', nama: 'REG' },
      ],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'nama',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.golonganObatService = new GolonganObatService()
  },
  mounted() {
    this.getParents()
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.golonganObatService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Golongan obat', this)
        })
        .finally(() => (this.isLoading = false))
    },
    getParents() {
      this.isLoading = true
      this.golonganObatService
        .get()
        .then((res) => {
          this.parents = res.data.data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Golongan obat', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onAddGolonganObat() {
      this.form = {
        id: 0,
      }
      this.dialog = true
    },
    onSaveGolonganObat(data) {
      this.isLoadingSave = true
      if (data && data.id === 0) {
        // add new
        this.golonganObatService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Golongan obat',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.getParents()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Golongan obat', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        // edit
        this.golonganObatService
          .update(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data Golongan obat',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Golongan obat', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteGolonganObat() {
      this.golonganObatService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Golongan obat',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
            this.getParents()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Golongan obat', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddGolonganObat()
      }
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.golonganObatService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-golongan-obat.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Golongan Obat', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
  },
}
</script>
