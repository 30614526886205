<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">User</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / User & akses / User</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button icon="pi pi-plus" label="User" @click="onAddUser()" />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        :has-header="true"
        @request="onRequestData"
      >
        <template #header>
          <div style="text-align: left">
            <MultiSelect
              :modelValue="selectedColumns"
              :options="columns"
              optionLabel="header"
              @update:modelValue="onToggle"
              placeholder="Pilih kolom tambahan"
              style="width: 20em"
            />
          </div>
        </template>
        <template #columns>
          <Column field="name" header="Nama" sortable>
            <template #body="{ data }">
              {{ data.name }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Cari nama"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="username"
            header="Username"
            style="width: 190px"
            sortable
          >
            <template #body="{ data }">
              {{ data.username }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Cari username"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="email" header="Email" style="width: 270px" sortable>
            <template #body="{ data }">
              {{ data.email }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Cari email"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column field="roles" header="Role" style="width: 7rem">
            <template #body="{ data }">
              {{ data.roles.join(', ') }}
            </template>
          </Column>
          <Column
            v-for="(col, index) of selectedColumns"
            :field="col.field"
            :header="col.header"
            :key="col.field + '_' + index"
          />
          <Column field="approved" header="Approved">
            <template #body="{ data }">
              <span :class="'status-badge status-' + data.approved">{{
                data.approved
              }}</span>
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit user' : 'Tambah user'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
      :closable="true"
    >
      <form-user
        :item="form"
        :roles="roles"
        :loading="isLoadingSave"
        @close="onCloseFormUser"
        @save="onSaveUser"
      />
    </Dialog>
    <Dialog
      header="Hapus user"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >User <strong>{{ form.name }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteUser"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import RoleService from '@/services/RoleService'
import FormUser from '@/components/master/FormUser'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'User',
  components: {
    FormUser,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      selectedColumns: null,
      columns: null,
      userService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      roles: [],
      items: [],
      form: {},
      initialValue: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'name',
        sortOrder: -1,
        filters: null,
      },
      filters: {
        username: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.userService = new UserService()
    this.columns = [
      { field: 'phone', header: 'Telepon' },
      { field: 'status', header: 'Status' },
    ]
    const roleService = new RoleService()
    roleService.get().then((res) => {
      this.roles = res.data.data
    })
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.userService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data area', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.initialValue = Object.assign({}, data)
      this.dialog = true
    },
    onAddUser() {
      this.form = {
        id: 0,
        roles: [],
        status: 'active',
        approved: 'approved',
      }
      this.dialog = true
    },
    onSaveUser(form) {
      let data = Object.assign({}, form)
      data.type = data.roles.map((e) => e.name)
      this.isLoadingSave = true
      if (data && data.id === 0) {
        // add new user
        this.userService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data User',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data User', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        // edit
        const userForm = Object.assign({}, data)
        userForm.type = data.roles.map((e) => e.name)
        // hapus property dari request jika value tidak berubah
        if (this.initialValue.username === data.username) {
          delete userForm.username
        }

        if (this.initialValue.phone === data.phone) {
          delete userForm.phone
        }

        if (this.initialValue.email === data.email) {
          delete userForm.email
        }

        if (!data.password) {
          delete userForm.password
        }

        this.userService
          .update(userForm)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data User',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data User', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteUser() {
      this.userService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data User',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data User', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onCloseFormUser() {
      this.dialog = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddUser()
      }
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    },
  },
}
</script>
