<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Margin Global</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Margin Global</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-between mb-2">
        <Dropdown
          v-model="pabrik_id"
          :filter="true"
          :options="list_pabrik"
          optionValue="id"
          optionLabel="nama"
          placeholder="Pilih pabrik"
          @change="loadData"
        />
        <Button icon="pi pi-plus" label="Margin" @click="onAdd()" />
      </div>
      <grid-margin
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @edit="onEdit"
        @delete="onConfirmDelete"
        @request="onRequestData"
      />
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit margin' : 'Tambah margin'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-margin
        :item="form"
        :list-pabrik="list_pabrik"
        :loading="isLoadingSave"
        @save="onSave"
      />
    </Dialog>
    <Dialog
      header="Hapus margin"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span>Margin akan dihapus. Proses ?</span>
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDelete"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PabrikService from '@/services/PabrikService'
import MarginGlobalService from '@/services/MarginGlobalService'
import FormMargin from '@/components/master/FormMarginGlobal'
import GridMargin from '@/components/master/GridMargin'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'

export default {
  name: 'MarginGlobal',
  components: {
    FormMargin,
    GridMargin,
    Hotkey,
  },
  data() {
    return {
      pabrik_id: 0,
      marginService: null,
      list_pabrik: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      form: {},
      initialValue: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'nama',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.marginService = new MarginGlobalService()
  },
  mounted() {
    const self = this
    Promise.all([this.getPabrik()]).then(function (result) {
      self.list_pabrik = result[0]
    })
  },
  methods: {
    async getPabrik() {
      const pabrikService = new PabrikService()
      return await pabrikService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data area', this)
        })
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.marginService
        .paginate(this.pabrik_id, page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
        })
        .catch((err) => {
          errorHandler(err, 'Margin Global', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEdit(data) {
      this.form = Object.assign({}, data)
      this.form.margin_at = new Date(data.margin_at)
      this.initialValue = Object.assign({}, data)
      this.dialog = true
    },
    onAdd() {
      this.form = {
        id: 0,
        pabrik_id: null,
        margin_bruto_A: 0,
        margin_bruto_B: 0,
        margin_bruto_C: 0,
        margin_bruto_D: 0,
        margin_bruto_E: 0,
        margin_bruto_F: 0,
        margin_at: null,
      }
      this.dialog = true
    },
    onSave(data) {
      this.isLoadingSave = true
      this.pabrik_id = data.pabrik_id
      if (data && data.id === 0) {
        this.marginService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Margin Global',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Margin Global', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else if (data && data.id >= 0) {
        this.marginService
          .update(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Margin Global',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Margin Global', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDelete(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDelete() {
      this.marginService
        .delete(this.form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Margin Global',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Margin Global', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAdd()
      }
    },
  },
}
</script>
