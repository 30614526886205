<template>
  <div>
    <DataTable
      :value="items"
      :loading="loading"
      :row-hover="true"
      class="p-datatable-sm"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="termin" header="Termin" style="min-width: 5rem" />
      <Column field="persentase" header="Persentase (%)" sortable />
      <Column
        header="&nbsp;"
        style="display: flex; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        termin_from: { value: null, matchMode: FilterMatchMode.CONTAINS },
        termin_to: { value: null, matchMode: FilterMatchMode.CONTAINS },
        persentase: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
}
</script>
