<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Role</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"
          >/ Master / User & akses / Role & Permission</span
        >
      </div>
    </div>
    <div class="card bg-white">
      <grid-role :items="roles" :loading="isLoading" @edit="onEditRole" />
    </div>
    <Dialog
      header="Edit Role"
      v-model:visible="dialogRole"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw' }"
      modal
      maximizable
    >
      <form-role
        :item="form"
        :permissions="permissions"
        :selected="selected"
        @close="onFormClose"
      />
    </Dialog>
  </div>
</template>

<script>
import GridRole from '@/components/master/GridRole'
import FormRole from '@/components/master/FormRole'
import RoleService from '@/services/RoleService'
import PermissionService from '@/services/PermissionService'

export default {
  components: {
    FormRole,
    GridRole,
  },
  data() {
    return {
      isLoading: false,
      dialogRole: false,
      roles: [],
      selected: [],
      permissions: [],
      form: {},
    }
  },
  mounted() {
    const roleService = new RoleService()
    const permissionService = new PermissionService()
    this.isLoading = true
    roleService.get().then((res) => {
      this.roles = res.data.data
      this.isLoading = false
    })
    permissionService.get().then((res) => {
      this.permissions = res.data.data
    })
  },
  methods: {
    onEditRole(e) {
      const roleService = new RoleService()
      roleService.permission(e.id).then((res) => {
        this.selected = res.data.data
        this.form = e
        this.dialogRole = true
      })
    },
    onFormClose() {
      this.dialogRole = false
    },
  },
}
</script>
