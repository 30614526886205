import http from '../clients/Axios'

export default class KomisiService {
  async add(form) {
    return await http.post('/salesman/komisi', form)
  }

  async update(form) {
    return await http.post(`/salesman/komisi/${form.id}`, form)
  }

  async delete(id) {
    return await http.delete(`/salesman/komisi/${id}`)
  }

  async get() {
    return await http.get('/salesman/komisi')
  }

  async export() {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/salesman/komisi/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
