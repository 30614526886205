<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Identitas perusahaan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Identitas perusahaan</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="card bg-white pt-4">
          <form @submit.prevent="handleSubmit(!v$.$invalid)">
            <div class="grid">
              <div class="col-12 md:col-6">
                <div class="formgrid grid">
                  <div class="field col-12">
                    <label
                      :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
                      >Nama perusahaan *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="v$.form.nama.$model"
                      type="text"
                      :class="{
                        'p-invalid': v$.form.nama.$invalid && submitted,
                      }"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.nama.$invalid && submitted) ||
                        v$.form.nama.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.nama.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12">
                    <label
                      :class="{
                        'p-error': v$.form.alamat.$invalid && submitted,
                      }"
                      >Alamat *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <Textarea
                      v-else
                      v-model="v$.form.alamat.$model"
                      :class="{
                        'p-invalid': v$.form.alamat.$invalid && submitted,
                      }"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.alamat.$invalid && submitted) ||
                        v$.form.alamat.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.alamat.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12 md:col-6">
                    <label
                      :class="{ 'p-error': v$.form.kota.$invalid && submitted }"
                      >Kota *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.kota"
                      type="text"
                      :class="{
                        'p-invalid': v$.form.kota.$invalid && submitted,
                      }"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.kota.$invalid && submitted) ||
                        v$.form.kota.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.kota.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12 md:col-6">
                    <label
                      :class="{
                        'p-error': v$.form.kode_pos.$invalid && submitted,
                      }"
                      >Kode pos *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.kode_pos"
                      type="text"
                      :class="{
                        'p-invalid': v$.form.kode_pos.$invalid && submitted,
                      }"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.kode_pos.$invalid && submitted) ||
                        v$.form.kode_pos.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.kode_pos.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12 lg:col-4">
                    <label>Telepon (1)</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.telepon_1"
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-4">
                    <label>Telepon (2)</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.telepon_2"
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-4">
                    <label>Fax</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.fax"
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label
                      :class="{ 'p-error': v$.form.npwp.$invalid && submitted }"
                      >NPWP *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.npwp"
                      :class="{
                        'p-invalid': v$.form.npwp.$invalid && submitted,
                      }"
                      class="w-full"
                      type="text"
                    />
                    <small
                      v-if="
                        (v$.form.npwp.$invalid && submitted) ||
                        v$.form.npwp.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.npwp.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label>Status Pajak</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <Dropdown
                      v-else
                      v-model="form.status_pajak"
                      :options="[
                        { id: 'pkp', nama: 'PKP' },
                        { id: 'non-pkp', nama: 'Non PKP' },
                      ]"
                      optionValue="id"
                      optionLabel="nama"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label
                      :class="{
                        'p-error': v$.form.no_pbf.$invalid && submitted,
                      }"
                      >No PBF *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.no_pbf"
                      :class="{
                        'p-invalid': v$.form.no_pbf.$invalid && submitted,
                      }"
                      class="w-full"
                      type="text"
                    />
                    <small
                      v-if="
                        (v$.form.no_pbf.$invalid && submitted) ||
                        v$.form.no_pbf.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.no_pbf.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label
                      :class="{
                        'p-error': v$.form.no_sipa.$invalid && submitted,
                      }"
                      >No. SIPA *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.no_sipa"
                      :class="{
                        'p-invalid': v$.form.no_sipa.$invalid && submitted,
                      }"
                      type="text"
                      class="w-full"
                    />
                    <small
                      v-if="
                        (v$.form.no_sipa.$invalid && submitted) ||
                        v$.form.no_sipa.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.no_sipa.required.$message }}</small
                    >
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label>No CDOB</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-model="form.no_cdob"
                      v-else
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label>No KTP Direktur</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-model="form.no_ktp_direktur"
                      v-else
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label>No KTP Apoteker</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-model="form.no_ktp_apoteker"
                      v-else
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label>Email perusahaan</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-model="form.email_perusahaan"
                      v-else
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label>Email apoteker</label>
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-model="form.email_apoteker"
                      v-else
                      type="text"
                      class="w-full"
                    />
                  </div>
                  <div class="field col-12 lg:col-6">
                    <label
                      :class="{
                        'p-error': v$.form.ttd_faktur.$invalid && submitted,
                      }"
                      >Tanda tangan Faktur *</label
                    >
                    <Skeleton v-if="isLoading" width="100%" height="35px" />
                    <InputText
                      v-else
                      v-model="form.ttd_faktur"
                      :class="{
                        'p-invalid': v$.form.ttd_faktur.$invalid && submitted,
                      }"
                      class="w-full"
                      type="text"
                    />
                    <small
                      v-if="
                        (v$.form.ttd_faktur.$invalid && submitted) ||
                        v$.form.ttd_faktur.$pending.$response
                      "
                      class="p-error"
                      >{{ v$.form.ttd_faktur.required.$message }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="hidden md:block lg:col-1">
                <Divider layout="vertical" />
              </div>
              <div class="col-12 md:col-5">
                <div class="flex justify-content-end align-items-end">
                  <Button
                    label="Rekening bank"
                    icon="pi pi-plus"
                    class="p-button-sm mb-3"
                    @click="onAddRekening"
                  />
                </div>
                <DataTable
                  :value="form.rekening"
                  :loading="isLoading"
                  responsiveLayout="scroll"
                  class="p-0"
                >
                  <template #loading>
                    <div class="text-center">Memuat data, harap tunggu...</div>
                  </template>
                  <Column field="bank" header="Bank" class="p-2">
                    <template #body="{ data }">
                      <div class="font-bold">{{ data.bank }}</div>
                      <div class="text-gray-600 text-xs" v-if="data.cabang">
                        Cabang {{ data.cabang }}
                      </div>
                    </template>
                  </Column>
                  <Column field="nama" header="Rekening" class="p-2">
                    <template #body="{ data }">
                      <div class="font-bold">No. {{ data.rekening }}</div>
                      <div class="text-gray-600 text-xs">
                        a/n {{ data.nama }}
                      </div>
                    </template>
                  </Column>
                  <Column>
                    <template #body="{ data }">
                      <div class="flex justify-content-end align-items-end">
                        <Button
                          type="button"
                          icon="pi pi-pencil"
                          class="
                            p-button-outlined p-button-secondary p-button-text
                            mr-2
                          "
                          @click="onEditRekening(data)"
                        />
                        <Button
                          type="button"
                          icon="pi pi-trash"
                          class="
                            p-button-outlined p-button-danger p-button-text
                          "
                          @click="onConfirmDeletion(data)"
                        />
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>

            <Dialog
              :header="
                rekening && rekening.id > 0
                  ? 'Edit Rekening'
                  : 'Tambah Rekening'
              "
              v-model:visible="dialog"
              :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
              :style="{ width: '50vw' }"
              :modal="true"
            >
              <form-rekening
                :loading="isLoadingRekening"
                :item="rekening"
                @save="onSaveRekening"
              />
            </Dialog>

            <Dialog
              header="Hapus area"
              v-model:visible="dialogHapus"
              :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
              :style="{ width: '50vw' }"
              :modal="true"
            >
              <div class="confirmation-content">
                <span
                  >Rekening
                  <strong
                    >{{ rekening.bank }} No. {{ rekening.rekening }} a.n
                    {{ rekening.nama }}</strong
                  >
                  akan dihapus. Proses ?</span
                >
              </div>
              <template #footer>
                <Button
                  label="Hapus"
                  icon="pi pi-trash"
                  @click="onDeleteRekening"
                  class="p-button-text p-button-danger"
                />
              </template>
            </Dialog>
            <div class="flex justify-content-end">
              <Skeleton v-if="isLoading" width="110px" height="35px" />
              <Button
                v-else
                :loading="isLoadingSave"
                :disabled="disableSave"
                type="submit"
                label="Simpan"
                icon="pi pi-check"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import ProfileService from '@/services/ProfileService'
import errorHandler from '@/helpers/error-handler'
import FormRekening from '@/components/master/FormRekening'

export default {
  name: 'IdentitasPerusahaan',
  components: { FormRekening },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      profileService: null,
      form: {
        nama: '',
        alamat: '',
        kota: '',
        kode_pos: '',
        telepon_1: '',
        telepon_2: '',
        fax: '',
        email_perusahaan: '',
        email_apoteker: '',
        no_ktp_direktur: '',
        no_ktp_apoteker: '',
        npwp: '',
        status_pajak: 'pkp',
        no_pbf: '',
        ttd_faktur: '',
        no_sipa: '',
        no_cdob: '',
      },
      rekening: {
        id: 0,
        bank: '',
        cabang: '',
        nama: '',
        rekening: '',
      },
      dialog: false,
      dialogHapus: false,
      submitted: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingRekening: false,
      items: [],
      disableSave: false,
    }
  },
  created() {
    this.profileService = new ProfileService()
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.profileService
        .get()
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.disableSave = false
          }
        })
        .catch((err) => {
          this.disableSave = true
          errorHandler(err, 'Identitas perusahaan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      delete this.form.rekening

      this.isLoadingSave = true
      this.profileService
        .update(this.form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Identitas perusahaan',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Identitas perusahaan', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onAddRekening() {
      this.rekening = {
        id: 0,
        bank: '',
        cabang: '',
        nama: '',
        rekening: '',
      }
      this.dialog = true
    },
    onEditRekening(data) {
      this.rekening = Object.assign({}, data)
      this.dialog = true
    },
    onSaveRekening(data) {
      this.isLoadingRekening = true
      if (data && data.id === 0) {
        const newData = Object.assign({}, data)
        delete newData.id
        let listData = []
        listData.push(newData)

        this.profileService
          .addRekening({ rekening: listData })
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data rekening',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data rekening', this)
          })
          .finally(() => (this.isLoadingRekening = false))
      } else if (data && data.id >= 0) {
        this.profileService
          .updateRekening(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Data rekening',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.loadData()
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data rekening', this)
          })
          .finally(() => (this.isLoadingRekening = false))
      }
    },
    onConfirmDeletion(data) {
      this.rekening = Object.assign({}, data)
      this.dialogHapus = true
    },
    onDeleteRekening() {
      this.profileService
        .deleteRekening(this.rekening.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Rekening',
              detail: 'Data rekening berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data area', this)
        })
      this.dialogHapus = false
    },
  },
  validations() {
    return {
      form: {
        nama: {
          required: helpers.withMessage(
            'Nama perusahaan harus diisi.',
            required
          ),
        },
        npwp: {
          required: helpers.withMessage('NPWP harus diisi.', required),
        },
        alamat: {
          required: helpers.withMessage('Alamat harus diisi.', required),
        },
        kota: {
          required: helpers.withMessage('Kota harus diisi.', required),
        },
        kode_pos: {
          required: helpers.withMessage('Kode pos harus diisi.', required),
        },
        no_pbf: {
          required: helpers.withMessage('No. PBF harus diisi.', required),
        },
        no_sipa: {
          required: helpers.withMessage('No. SIPA harus diisi.', required),
        },
        ttd_faktur: {
          required: helpers.withMessage(
            'Tanda tangan faktur harus diisi.',
            required
          ),
        },
      },
    }
  },
}
</script>

<style scoped>
.no-header.p-datatable table thead tr {
  display: none;
}
</style>
