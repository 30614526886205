<template>
  <div>
    <DataTable
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      showGridlines
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <ColumnGroup type="header">
        <Row>
          <Column
            header="Margin Bersih"
            headerStyle="text-align: center"
            :colspan="6"
          />
          <Column header="Jadwal eksekusi" :rowspan="2" />
          <Column header="Waktu eksekusi" :rowspan="2" />
          <Column header="Dibuat oleh" :rowspan="2" />
          <Column header="" :rowspan="2" />
        </Row>
        <Row>
          <Column field="margin_bruto_A" header="A" />
          <Column field="margin_bruto_B" header="B" />
          <Column field="margin_bruto_C" header="C" />
          <Column field="margin_bruto_D" header="D" />
          <Column field="margin_bruto_E" header="E" />
          <Column field="margin_bruto_F" header="F" />
        </Row>
      </ColumnGroup>
      <Column field="margin_bruto_A" />
      <Column field="margin_bruto_B" />
      <Column field="margin_bruto_C" />
      <Column field="margin_bruto_D" />
      <Column field="margin_bruto_E" />
      <Column field="margin_bruto_F" />
      <Column field="margin_at">
        <template #body="{ data }">
          {{ formatDate(data.margin_at) }}
        </template>
      </Column>
      <Column field="applied_at">
        <template #body="{ data }">
          {{ formatDate(data.applied_at) }}
        </template>
      </Column>
      <Column field="created_by" />
      <Column>
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              v-if="!data.applied_at"
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            />
            <Button
              v-if="!data.applied_at"
              type="button"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-text"
              @click="$emit('delete', data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    ColumnGroup,
    Row,
  },
  data() {
    return {
      options: this.gridOptions,
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.filters
      this.options.page = 1 // reset to page 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatDate(value) {
      return formatDate(value, true)
    },
  },
}
</script>
