<template>
  <div>
    <DataTable
      :value="items"
      :row-hover="true"
      :loading="loading"
      :rows="10"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      paginator
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="display_name" header="Role" :sortable="true">
        <template #body="{ data }">
          {{ data.display_name }}
        </template>
      </Column>
      <Column style="width: 200px">
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              v-if="data.name !== 'admin'"
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    }
  },
}
</script>
