import http from '../clients/Axios'
const resource = 'access/permission'

export default class PermissionService {
  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/${resource}${param}`)
    return res
  }
}
