<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.nama.$invalid && submitted }"
            >Nama *</label
          >
          <InputText
            v-model="form.nama"
            :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
            type="text"
            class="w-full"
            autofocus
          />
          <small
            v-if="
              (v$.form.nama.$invalid && submitted) ||
              v$.form.nama.$pending.$response
            "
            class="p-error"
            >{{ v$.form.nama.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label :class="{ 'p-error': v$.form.rekening.$invalid && submitted }"
            >Rekening *</label
          >
          <InputText
            v-model="form.rekening"
            :class="{ 'p-invalid': v$.form.rekening.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.rekening.$invalid && submitted) ||
              v$.form.rekening.$pending.$response
            "
            class="p-error"
            >{{ v$.form.rekening.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0
        ? 'Tambah Daftar kas'
        : 'Edit Daftar kas'
    },
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        nama: {
          required: helpers.withMessage('Nama kas harus diisi.', required),
        },
        rekening: {
          required: helpers.withMessage('Rekening harus diisi.', required),
        },
      },
    }
  },
}
</script>
