import http from '../clients/Axios'
const resource = 'access/role'

export default class RoleService {
  async get() {
    const res = await http.get(`/${resource}`)
    return res
  }

  async permission(id) {
    const res = await http.get(`/${resource}/${id}/permission`)
    return res
  }

  async sync(form) {
    const res = await http.post(`/${resource}/${form.id}/permission/sync`, form)
    return res
  }

  async clear(form) {
    const res = await http.post(`/${resource}/${form.id}/permission/clear`)
    return res
  }
}
