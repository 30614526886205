<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label>Termin (dari)</label>
          <InputNumber
            v-model="form.termin_from"
            :class="{
              'p-invalid': v$.form.termin_from.$invalid && submitted,
            }"
            :minFractionDigits="0"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            align="right"
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>Termin (sampai)</label>
          <InputNumber
            v-model="form.termin_to"
            :class="{ 'p-invalid': v$.form.termin_to.$invalid && submitted }"
            :minFractionDigits="0"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
          />
        </div>
        <div class="field col-12 md:col-12">
          <label>Persentase</label>
          <div class="p-inputgroup">
            <InputNumber
              v-model="form.persentase"
              :class="{
                'p-invalid': v$.form.persentase.$invalid && submitted,
              }"
              :minFractionDigits="2"
              locale="id-ID"
              class="w-full"
              input-class="w-full"
            />
            <span class="p-inputgroup-addon">%</span>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    title() {
      return this.item && this.item.id === 0 ? 'Tambah Komisi' : 'Edit Komisi'
    },
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        termin_from: {
          required: helpers.withMessage('Termin (dari) harus diisi.', required),
        },
        termin_to: {
          required: helpers.withMessage(
            'Termin (sampai) harus diisi.',
            required
          ),
        },
        persentase: {
          required: helpers.withMessage('Persentase harus diisi.', required),
        },
      },
    }
  },
}
</script>
