import http from '../clients/Axios'

export default class ReferenceService {
  async get(type) {
    return await http.get(`/reference/${type}`)
  }

  async getKategoriObat() {
    return await http.get(`/reference/obat_kategori`)
  }
}
